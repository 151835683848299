
import { Vue, Component, Prop } from 'vue-property-decorator';
import PageContent from '@/components/PageContent.vue';
@Component({
    components: {
        PageContent,
    },
})
export default class ErrorPage extends Vue {
    @Prop({ default: 'server-error' }) state?: string;
    private errorImages = {
        'server-error': '#server-error',
        'access-error': '#no-access',
    };
    private errorHeading = {
        'server-error': 'Server Error',
        'access-error': 'Access Denied',
    };

    private errorSubHeading = {
        'server-error':
            'Something went wrong. Please try again or contact support if the problem persists.',
        'access-error':
            'You do not have permission to view this section. Please get in touch with your administrator for more details.',
    };
}
