export const ACTIONS_ENUM = Object.freeze({
    READ: 'READ',
    ACTION: 'ACTION',
});

export const SUBJECTS_ENUM = Object.freeze({
    RECOGNITION: 'RECOGNITION',
    EMPLOYEE_MASTER: 'EMPLOYEE_MASTER',
    REPORT_CENTRE: 'REPORT_CENTRE',
    INSIGHTS: 'INSIGHTS',
    ANNOUNCEMENTS: 'ANNOUNCEMENTS',
    SURVEYS: 'SURVEYS',
    BENEFITS: 'BENEFITS',
    INSTANT_REWARDS: 'INSTANT_REWARDS',

    ADMIN_CONSOLE: 'ADMIN_CONSOLE',
    COMPANY_BALANCE: 'COMPANY_BALANCE',
    THEMES: 'THEMES',
    ACCESS_CONTROL: 'ACCESS_CONTROL',
});
export const ROLES_ENUM = Object.freeze({
    ADMINISTRATION_ADMIN: 'ADMINISTRATION_ADMIN',
    ADMINISTRATION_VIEWER: 'ADMINISTRATION_VIEWER',
    PRODUCT_ADMIN: 'PRODUCT_ADMIN',
    PRODUCT_VIEWER: 'PRODUCT_VIEWER',
    REPORT_ADMIN: 'REPORT_ADMIN',
    REWARDS_ADMIN: 'REWARDS_ADMIN',
    DASHBOARD_VIEWER: 'DASHBOARD_VIEWER',
    DASHBOARD_ADMIN: 'DASHBOARD_ADMIN',
});

export type ACTIONS_TYPE = keyof typeof ACTIONS_ENUM;
export type SUBJECTS_TYPE = keyof typeof SUBJECTS_ENUM;

export const PERMISSION_GROUPS = Object.freeze({
    [ROLES_ENUM.ADMINISTRATION_ADMIN]: {
        [SUBJECTS_ENUM.RECOGNITION]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.EMPLOYEE_MASTER]: ACTIONS_ENUM.ACTION,
        //[SUBJECTS_ENUM.REPORT_CENTRE]: ACTIONS_ENUM.ACTION,
        //[SUBJECTS_ENUM.INSIGHTS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.ANNOUNCEMENTS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.SURVEYS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.BENEFITS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.INSTANT_REWARDS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.ADMIN_CONSOLE]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.COMPANY_BALANCE]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.THEMES]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.ACCESS_CONTROL]: ACTIONS_ENUM.ACTION,
    },
    [ROLES_ENUM.ADMINISTRATION_VIEWER]: {
        [SUBJECTS_ENUM.RECOGNITION]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.EMPLOYEE_MASTER]: ACTIONS_ENUM.READ,
        //[SUBJECTS_ENUM.REPORT_CENTRE]: ACTIONS_ENUM.ACTION,
        //[SUBJECTS_ENUM.INSIGHTS]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.ANNOUNCEMENTS]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.SURVEYS]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.BENEFITS]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.INSTANT_REWARDS]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.ADMIN_CONSOLE]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.COMPANY_BALANCE]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.THEMES]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.ACCESS_CONTROL]: ACTIONS_ENUM.READ,
    },
    [ROLES_ENUM.PRODUCT_ADMIN]: {
        [SUBJECTS_ENUM.RECOGNITION]: ACTIONS_ENUM.ACTION,
        //[SUBJECTS_ENUM.REPORT_CENTRE]: ACTIONS_ENUM.ACTION,
        //[SUBJECTS_ENUM.INSIGHTS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.ANNOUNCEMENTS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.SURVEYS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.BENEFITS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.INSTANT_REWARDS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.ADMIN_CONSOLE]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.COMPANY_BALANCE]: ACTIONS_ENUM.ACTION,
    },
    [ROLES_ENUM.PRODUCT_VIEWER]: {
        [SUBJECTS_ENUM.RECOGNITION]: ACTIONS_ENUM.READ,
        //[SUBJECTS_ENUM.REPORT_CENTRE]: ACTIONS_ENUM.READ,
        //[SUBJECTS_ENUM.INSIGHTS]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.ANNOUNCEMENTS]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.SURVEYS]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.BENEFITS]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.INSTANT_REWARDS]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.ADMIN_CONSOLE]: ACTIONS_ENUM.READ,
        [SUBJECTS_ENUM.COMPANY_BALANCE]: ACTIONS_ENUM.READ,
    },
    [ROLES_ENUM.REPORT_ADMIN]: {
        [SUBJECTS_ENUM.REPORT_CENTRE]: ACTIONS_ENUM.ACTION,
    },
    [ROLES_ENUM.REWARDS_ADMIN]: {
        //[SUBJECTS_ENUM.REPORT_CENTRE]: ACTIONS_ENUM.ACTION,
        //[SUBJECTS_ENUM.INSIGHTS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.INSTANT_REWARDS]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.ADMIN_CONSOLE]: ACTIONS_ENUM.ACTION,
        [SUBJECTS_ENUM.COMPANY_BALANCE]: ACTIONS_ENUM.ACTION,
    },
    [ROLES_ENUM.DASHBOARD_VIEWER]: {
        [SUBJECTS_ENUM.INSIGHTS]: ACTIONS_ENUM.READ,
    },
    [ROLES_ENUM.DASHBOARD_ADMIN]: {
        [SUBJECTS_ENUM.INSIGHTS]: ACTIONS_ENUM.ACTION,
    },
});

export const INITIAL_ROUTES = Object.freeze([
    {
        accesses: [
            {
                action: ACTIONS_ENUM.READ,
                subject: SUBJECTS_ENUM.RECOGNITION,
            },
        ],
        route: '/recognition',
    },
    {
        accesses: [
            {
                action: ACTIONS_ENUM.READ,
                subject: SUBJECTS_ENUM.EMPLOYEE_MASTER,
            },
        ],
        route: '/employee-master',
    },
    {
        accesses: [
            {
                action: ACTIONS_ENUM.READ,
                subject: SUBJECTS_ENUM.REPORT_CENTRE,
            },
        ],
        route: '/report-center',
    },
    {
        accesses: [
            {
                action: ACTIONS_ENUM.READ,
                subject: SUBJECTS_ENUM.INSIGHTS,
            },
        ],
        route: '/insights-center',
    },
    {
        accesses: [
            {
                action: ACTIONS_ENUM.READ,
                subject: SUBJECTS_ENUM.ADMIN_CONSOLE,
            },
        ],
        route: '/admin-console',
    },
]);
