import { AxiosInstance } from 'axios';

import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';
//import { MarketplaceServiceTypes } from './types';
// const baseURL: any = {
//     STAGE: 'https://marketplace-spotlight.mum1-stage.zeta.in/marketplace/1.0',
//     PREPROD: 'https://marketplace-spotlight.mum1-pp.zeta.in/marketplace/1.0',
//     PROD: 'https://marketplace-spotlight.mum1.zeta.in/marketplace/1.0',
//     PROD_UK: 'https://sb1-god-spotlight.lon1.zetaapps.in/marketplace/1.0',
//     SWAGGER:
//         'https://virtserver.swaggerhub.com/z3457/spotlight-marketplace/1.0.0-SNAPSHOT',
// };

class MarketplaceService {
    private axios: AxiosInstance;

    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
    }
    public fetch() {
        return this.axios.get('', {});
    }
    // private get servicePath() {
    //     return `/marketplace/1.0`;
    // }

    public getAccountBalances(pathParams: any, params: any = {}) {
        return this.axios.get(
            `/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/balance`,
            { params }
        );
    }
    public fetchCategories(pathParams: any = {}, params: any = {}) {
        // https://marketplace-spotlight.mum1-pp.zeta.in/marketplace/1.0/frontOffice/esp/146/corporates/22017/companies/22589/category?pageNumber=0&pageSize=15&catalogueTypes=INSTANT_REWARDS

        return this.axios.request({
            method: 'GET',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/category`,
            params: params,
        });
    }
    public fetchCategory(pathParams: any = {}, params: any = {}) {
        // https://marketplace-spotlight.mum1-pp.zeta.in/marketplace/1.0/frontOffice/esp/146/corporates/22017/companies/22589/category?pageNumber=0&pageSize=15&catalogueTypes=INSTANT_REWARDS

        return this.axios.request({
            method: 'GET',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/category/${pathParams.categoryId}`,
            params: params,
        });
    }
    public fetchSaleItems(
        pathParams: any,
        { params = {}, queryString = null }
    ) {
        return this.axios.request({
            method: 'GET',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${
                pathParams.espId
            }/corporates/${pathParams.corpId}/companies/${
                pathParams.compId
            }/saleItems${queryString ? '?&' + queryString : ''}`,
            params: params,
        });
    }
    public fetchSaleItemFilters(
        pathParams: any = {},
        { params, queryString } = { params: {}, queryString: null }
    ) {
        return this.axios.request({
            method: 'GET',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${
                pathParams.espId
            }/corporates/${pathParams.corpId}/companies/${
                pathParams.compId
            }/saleItems/filters${queryString ? '?&' + queryString : ''}`,
            params: params,
        });
    }
    public fetchSaleItemById(pathParams: any, id: string, config: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/saleItem/${id}`,
            ...config,
        });
    }
    public fetchSubCategoriesById(pathParams: any = {}, params: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/category/${pathParams.categoryId}/subcategories`,
            params,
        });
    }

    public validateBasket(pathParams: any = {}, payload: any = {}) {
        return this.axios.request({
            method: 'POST',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/marketplace/${pathParams.marketplaceId}/basket/update`,
            data: payload,
        });
    }

    public evaluateBasket(pathParams: any = {}, payload: any = {}) {
        return this.axios.request({
            method: 'POST',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/marketplace/${pathParams.marketplaceId}/basket/evaluate`,
            data: payload,
        });
    }
    public placeOrder(pathParams: any = {}, payload: any = {}) {
        return this.axios.request({
            method: 'POST',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/marketplace/${pathParams.marketplaceId}/v1/order/place`,
            data: payload,
        });
    }
    public placeDistributionOrder(pathParams: any = {}, payload: any = {}) {
        return this.axios.request({
            method: 'POST',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/marketplace/${pathParams.marketplaceId}/v1/jobReferences/${pathParams.orderId}/uploadFile`,
            data: payload,
        });
    }

    public fetchBillingAddress(pathParams: any) {
        return this.axios.request({
            method: 'GET',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/marketplace/${pathParams.marketplaceId}/v1/billingAddress`,
        });
    }

    public downloadIrDistributionTemplate(pathParams: any) {
        return this.axios.request({
            method: 'PUT',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/marketplace/${pathParams.marketplaceId}/v1/jobReferences/${pathParams.orderId}/data/FILE/CSV`,
        });
    }

    public fetchOrderHistory(pathParams: any, params: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/marketplace/${pathParams.marketplaceId}/orders`,
            params,
        });
    }

    public fetchMarketplaceProvider(pathParams: any, config: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `/marketplaceProvider/ESP/${pathParams.espId}`,
        });
    }

    public fetchOrderDetailsById(
        pathParams: any,
        id: string,
        config: any = {}
    ) {
        return this.axios.request({
            method: 'GET',
            url: `/frontOffice/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/marketplace/${pathParams.marketplaceId}/order/${id}`,
            ...config,
        });
    }

    // public fetchSaleItemsByCategoryId(id: number, config: any = {}) {
    //     return this.axios.request({
    //         method: 'GET',
    //         url: `/user/saleItems?categoryIds=${id}&&catalogueTypes=DISCOUNT`,
    //         ...config,
    //     });
    // }
}

const config = {
    baseURL: __APP__.MARKETPLACE.BASE_URL,
    resolveAuthToken: () => {
        const token: string = localStorage.getItem('@zeta::authToken') || '';
        return token;
    },
};
export default new MarketplaceService(config);
