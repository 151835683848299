import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';

const BUISNESS_THEME = 'buisnessTheme';
export const USER_DETAILS = 'userDetail';
export const ACCOUNT_HOLDER_DETAILS = 'accountHolderDetails';
const getters: GetterTree<RootState, {}> = {
    [BUISNESS_THEME]: state => {
        return state.businessTheme;
    },
    [USER_DETAILS]: state => {
        return state.userDetail;
    },
    [ACCOUNT_HOLDER_DETAILS]: state => {
        return state.accountHolderDetails;
    },
};

export default getters;
