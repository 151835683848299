import Vue from 'vue';

const Events = Object.freeze({
    showLoader: 'showLoader',
    hideLoader: 'hideLoader',
    unAuthorised: 'unAuthorised',
    logout: 'logout',
    showMessage: 'showMessage',
    logoutDialog: 'logoutDialog',
    refreshDiaLog: 'refreshDialog',
    updateSidebarCount: 'updateSidebarCount',
    logEvent: 'logEvent',
    logPendoEvent: 'logPendoEvent',
    showPendoGuideByName: 'showPendoGuideByName',
    logFirebaseEvent: 'logFirebaseEvent',
});

const EventBus = new Vue();

export { Events, EventBus };
