import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';
import { ACTIONS_ENUM, SUBJECTS_ENUM } from '@/commons/accessControl';

const RecognitionPage = () =>
    import(
        /* webpackChunkName: "RecognitionPage" */ '@/modules/recognition/Recognition.vue'
    );
const RecognitionLandingPage = () =>
    import(
        /* webpackChunkName: "RecognitionLandingPage" */ '@/modules/recognition/pages/RecognitionLandingPage.vue'
    );

// const AudiencePreviewPage = () =>
//     import(
//         /* webpackChunkName: "AudiencePreviewPage" */ '@/modules/recognition/pages/AudiencePreviewPage.vue'
//     );

const CreateProgramPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/CreateProgramPage.vue'
    );
const ProgramDetailPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/ProgramDetailPage.vue'
    );

const AwardCreateEditPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/AwardCreateEditPage.vue'
    );
const CelebrateAwardCreateEditPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/CelebrateAwardCreateEditPage.vue'
    );
const NominateAwardCreateEditPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/NominateAwardCreateEditPage.vue'
    );
const AwardDetailPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/AwardDetailPage.vue'
    );
const AllocateBudgetPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/AllocateBudgetPage.vue'
    );
const AllocationHistory = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/AllocationHistory.vue'
    );
const AllocationHistoryDetailPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/AllocationHistoryDetailPage.vue'
    );
const ProgramWrapperPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/ProgramWrapperPage.vue'
    );
const BulkAllocateBudgetPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/BulkAllocateBudgetPage.vue'
    );
const RevokeBudgetPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/RevokeBudgetPage.vue'
    );
const NominationDetailsPage = () =>
    import(
        /* webpackChunkName: "CreateProgramPage" */ '@/modules/recognition/pages/NominationDetailsPage.vue'
    );

const routes: RouteConfig[] = [
    {
        path: MODULES.RECOGNITION.path,
        component: RecognitionPage,
        children: [
            {
                path: '',
                name: pages.VoucherLandingPage.name,
                component: RecognitionLandingPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.RECOGNITION,
                        },
                    ],
                },
            },
            {
                path: 'create-program',
                name: pages.CreateProgramPage.name,
                component: CreateProgramPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.RECOGNITION,
                        },
                    ],
                },
            },
            {
                path: 'programs/detail/:id',
                name: 'ProgramDetailPage',
                component: ProgramDetailPage,
                props: true,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.RECOGNITION,
                        },
                    ],
                },
            },
            {
                path: 'programs/:programId/',
                name: 'ProgramWrapperPage',
                component: ProgramWrapperPage,
                props: true,
                children: [
                    {
                        path: 'allocation-history',
                        name: 'AllocationHistory',
                        component: AllocationHistory,
                        props: true,
                        meta: {
                            accesses: [
                                {
                                    action: ACTIONS_ENUM.READ,
                                    subject: SUBJECTS_ENUM.RECOGNITION,
                                },
                            ],
                        },
                    },
                    {
                        path: 'awards/create',
                        name: 'AwardCreatePage',
                        component: AwardCreateEditPage,
                        props: true,
                        meta: {
                            accesses: [
                                {
                                    action: ACTIONS_ENUM.ACTION,
                                    subject: SUBJECTS_ENUM.RECOGNITION,
                                },
                            ],
                        },
                    },
                    {
                        path: 'awards/celebrate-create',
                        name: 'CelebrateAwardCreateEditPage',
                        component: CelebrateAwardCreateEditPage,
                        props: true,
                        meta: {
                            accesses: [
                                {
                                    action: ACTIONS_ENUM.ACTION,
                                    subject: SUBJECTS_ENUM.RECOGNITION,
                                },
                            ],
                        },
                    },
                    {
                        path: 'awards/nominate-create',
                        name: 'NominateAwardCreateEditPage',
                        component: NominateAwardCreateEditPage,
                        props: true,
                        meta: {
                            accesses: [
                                {
                                    action: ACTIONS_ENUM.ACTION,
                                    subject: SUBJECTS_ENUM.RECOGNITION,
                                },
                            ],
                        },
                    },
                    {
                        path: 'allocate-budget',
                        name: 'AllocateBudget',
                        component: AllocateBudgetPage,
                        props: true,
                        meta: {
                            accesses: [
                                {
                                    action: ACTIONS_ENUM.ACTION,
                                    subject: SUBJECTS_ENUM.RECOGNITION,
                                },
                            ],
                        },
                    },
                    {
                        path: 'revoke-budget',
                        name: 'revokeBudget',
                        component: RevokeBudgetPage,
                        props: true,
                        meta: {
                            accesses: [
                                {
                                    action: ACTIONS_ENUM.ACTION,
                                    subject: SUBJECTS_ENUM.RECOGNITION,
                                },
                            ],
                        },
                    },
                    {
                        path: 'bulk-allocate-budget',
                        name: 'BulkAllocateBudget',
                        component: BulkAllocateBudgetPage,
                        props: true,
                        meta: {
                            accesses: [
                                {
                                    action: ACTIONS_ENUM.ACTION,
                                    subject: SUBJECTS_ENUM.RECOGNITION,
                                },
                            ],
                        },
                    },
                    {
                        path: 'allocation-history/:id',
                        name: 'AllocationHistoryDetail',
                        component: AllocationHistoryDetailPage,
                        props: true,
                        meta: {
                            accesses: [
                                {
                                    action: ACTIONS_ENUM.READ,
                                    subject: SUBJECTS_ENUM.RECOGNITION,
                                },
                            ],
                        },
                    },
                    {
                        path: 'awards/detail/:id',
                        name: 'AwardDetailPage',
                        component: AwardDetailPage,
                        props: true,
                        meta: {
                            accesses: [
                                {
                                    action: ACTIONS_ENUM.READ,
                                    subject: SUBJECTS_ENUM.RECOGNITION,
                                },
                            ],
                        },
                    },
                    {
                        path: 'nomination/:id',
                        name: 'NominationDetailsPage',
                        component: NominationDetailsPage,
                        props: true,
                        meta: {
                            accesses: [
                                {
                                    action: ACTIONS_ENUM.READ,
                                    subject: SUBJECTS_ENUM.RECOGNITION,
                                },
                            ],
                        },
                    },
                ],
            },

            // {
            //     path: 'programs/:programId/allocation-history',
            //     name: 'AllocationHistory',
            //     component: AllocationHistory,
            //     props: true,
            // },

            // {
            //     path: 'programs/:programId/awards/edit/:id',
            //     name: 'ProgramDetaAwardEditPageilPage',
            //     component: AwardCreateEditPage,
            //     props: true
            // },
        ],
    },
];

export default routes;
