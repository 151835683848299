import { ActionTree } from 'vuex';
import { RootState, SidebarItem } from '@/store/types';
import {
    UPDATE_USER_DETAIL,
    UPDATE_COMPANY_DETAIL,
    UPDATE_ACCOUNT_HOLDER_DETAILS,
} from './mutations';
import { EventBus, Events } from '@/commons/eventBus';
import {
    FETCH_USER_DETAIL,
    FETCH_COMPANY_ATTRIBUTES,
    FETCH_ACCOUNT_HOLDER_DETAILS,
    UPDATE_COMPANY_ATTRIBUTES,
    LOG_OUT,
    FETCH_USER_ROLES,
} from './actions.types';
import CorpCoreService from '../services/corpcore';
import zetaSSC from '@/services/zetaSSC';
import RewardsSevice from '@/services/rewards';
const actions: ActionTree<{}, RootState> = {
    async [FETCH_USER_DETAIL]({ rootState, commit }, payload) {
        return new Promise((resolve, reject) => {
            CorpCoreService.resolveEmployee(payload)
                .then(
                    resp => {
                        // commit(UPDATE_PROGRAM_DETAIL, resp.data.data);
                        // const hardcodedEspId = config[__APP__.ENV].espId;
                        const localTenantId = window.localStorage.getItem(
                            'sessionTenantInfo'
                        );
                        if (!localTenantId) {
                            throw new Error('#LOGOUT - Invalid Tenant id ');
                        }
                        //  debugger;
                        const employee: any = resp.data.find(
                            (emp: any) => emp.tenantID == localTenantId
                        );
                        const {
                            employeeID: empID,
                            espID,
                            corpID: corporateID,
                            companyID,
                            zetaUserID: userID,
                            tenantID,
                            ssoIdentity,
                        } = employee;
                        commit(UPDATE_USER_DETAIL, {
                            ...employee.employee,
                            ssoIdentity,
                            profilePic: employee.employee.attributes.profilePic,
                        });
                        commit(UPDATE_COMPANY_DETAIL, {
                            espId: espID,
                            corpId: corporateID,
                            compId: companyID,
                            tenantId: tenantID,
                        });
                        resolve(employee.employee);
                    },
                    err => {
                        if (err?.response?.status == 401) {
                            throw new Error(
                                '#LOGOUT - get employee profile throwing 401'
                            );
                        }
                        reject({
                            isError: true,
                            ...err.response,
                        });
                    }
                )
                .catch(reject);
        });
    },
    async [FETCH_ACCOUNT_HOLDER_DETAILS]({ rootState, commit }, params) {
        // EventBus.$emit(Events.showLoader);
        const localTenantId = window.localStorage.getItem('sessionTenantInfo');
        return new Promise(resolve => {
            RewardsSevice.fetchUserAccountHolderDetails(
                { tenantId: localTenantId },
                params
            ).then(
                resp => {
                    // EventBus.$emit(Events.hideLoader);
                    let ahDetails: any = resp.data.data;

                    ahDetails = {
                        ...ahDetails,
                        accountHolderId: ahDetails.auraId,
                    };

                    commit(UPDATE_ACCOUNT_HOLDER_DETAILS, ahDetails);
                    resolve(ahDetails);
                },
                err => {
                    // EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_COMPANY_ATTRIBUTES]({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            CorpCoreService.fetchCompanyAttributes({
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            })
                .then(res => {
                    if (res.data) {
                        const { attributes } = res.data.companySummary.company;

                        commit(UPDATE_COMPANY_ATTRIBUTES, attributes);
                        commit(UPDATE_COMPANY_DETAIL, {
                            subTenantId: attributes.luminousSubtenantId,
                        });
                        resolve(attributes);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    async [UPDATE_COMPANY_ATTRIBUTES]({ rootState, commit }, attributes) {
        return new Promise((resolve, reject) => {
            CorpCoreService.updateCompanyAttributes(
                {
                    espId: rootState.espId,
                    corpId: rootState.corpId,
                    compId: rootState.compId,
                    tenantId: rootState.tenantId,
                },
                attributes
            )
                .then(async () => {
                    const res = await CorpCoreService.fetchCompanyAttributes({
                        espId: rootState.espId,
                        corpId: rootState.corpId,
                        compId: rootState.compId,
                        tenantId: rootState.tenantId,
                    });

                    if (res.data) {
                        const { attributes } = res.data.companySummary.company;

                        commit(UPDATE_COMPANY_ATTRIBUTES, attributes);

                        resolve(attributes);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    async [LOG_OUT]({ rootState, commit }) {
        EventBus.$emit(Events.showLoader);
        return new Promise((resolve, reject) => {
            fetch(`${__APP__.CIPHER_PARAMS.baseUrl}/access_token/logout`, {
                method: 'POST',
                headers: {
                    Authorization:
                        'Bearer ' +
                        window.localStorage.getItem('@zeta::authToken'),
                },
            })
                .then(function(res) {
                    EventBus.$emit(Events.hideLoader);
                    if (res.ok) {
                        window.localStorage.clear();
                        window.location.href = '/auth/login';
                        resolve(true);
                    } else {
                        // For now, atleast do a shallow logout if api fails
                        console.log('#error in logout', res);
                        window.localStorage.clear();
                        window.location.href = '/auth/login';
                        reject(false);
                    }
                })
                .catch(function(err) {
                    EventBus.$emit(Events.hideLoader);
                    window.localStorage.clear();
                    window.location.href = '/auth/login';
                    reject(false);
                });
        });
    },
    async [FETCH_USER_ROLES]({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            CorpCoreService.fetchUserRoles({
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            })
                .then(response => response.data)
                .then(function(res) {
                    if (res.roles) {
                        resolve(res.roles);
                    } else {
                        reject(new Error('Roles api failed'));
                    }
                })
                .catch(function(err) {
                    reject(new Error('Roles api failed'));
                });
        });
    },
};

export default actions;
