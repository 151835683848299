import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { EmployeeMasterState } from './employeeMaster.state';

export const EMPLOYEE_EDIT_DATA = 'employeeEditData';

const getters: GetterTree<EmployeeMasterState, RootState> = {
    [EMPLOYEE_EDIT_DATA]: state => {
        const employeeData = state.employeeData || {};
        return employeeData;
    },
};

export default getters;
