import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { RootState } from '@/store/types';
import actions from './voucher.actions';
import getters from './voucher.getters';
import mutations from './voucher.mutations';
import state from './voucher.state';

import { VoucherState } from './voucher.types';

const module: Module<VoucherState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export const VOUCHER_STORE = 'voucher';

export const VoucherModule = namespace(VOUCHER_STORE);

export default module;
