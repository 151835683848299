
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SidebarItemGroup extends Vue {
    @Prop({ default: '' }) private text!: string;
    @Prop({ default: '' }) private subText!: string;
    @Prop({ default: false }) private isExpanded!: boolean; // for initial state
    private show = false;

    private created() {
        this.show = this.isExpanded;
    }
}
