//import { RecognitionState } from './recognition.types';

import { namespace } from 'vuex-class';

export interface RecognitionState {
    createProgram: any;
    programDetail: any;
    badges: any[];
    productConfig: any;
    groups?: any[];
    tab: number;
    programPresets: any[];
    awardPresets: any[];
    currencyList: any[];
    balance: any;
    audience: any;
    revokeDetails: any;
}

export const PROGRAM_DETAIL = 'programDetail';
export const GROUPS = 'groups';
export const PRODUCT_CONFIG = 'productConfig';
export const TAB = 'tab';
export const PROGRAM_PRESETS = 'programPresets';
export const AWARD_PRESETS = 'awardPresets';
export const CURRENCY_LIST = 'currencyList';
export const AUDIENCE = 'audience';

const state: RecognitionState = {
    createProgram: null,
    programDetail: null,
    badges: [],
    productConfig: {},
    groups: [],
    tab: 0,
    programPresets: [],
    awardPresets: [],
    currencyList: [],
    balance: null,
    audience: {},
    revokeDetails: null,
};

export default state;

export const RECOGNITION_STORE = 'recognition';

export const RecognitionModule = namespace(RECOGNITION_STORE);
