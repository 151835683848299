export const MODULES_ENUM = Object.freeze({
    BENEFITS: 'BENEFITS',
    VOUCHER: 'VOUCHER',
    RECOGNITION: 'RECOGNITION',
    EMPLOYEE_MASTER: 'EMPLOYEE_MASTER',
    AUTH: 'AUTH',
    INSTANT_REWARDS: 'INSTANT_REWARDS',
});

export const MODULES = Object.freeze({
    [MODULES_ENUM.BENEFITS]: {
        path: '/benefits',
        id: MODULES_ENUM.BENEFITS,
        name: 'Benefits',
    },
    [MODULES_ENUM.VOUCHER]: {
        path: '/vouchers',
        id: MODULES_ENUM.VOUCHER,
        name: 'Voucher Shop',
    },
    [MODULES_ENUM.RECOGNITION]: {
        path: '/recognition',
        id: MODULES_ENUM.RECOGNITION,
        name: 'Recognition',
    },
    [MODULES_ENUM.EMPLOYEE_MASTER]: {
        path: '/employee-master',
        id: MODULES_ENUM.EMPLOYEE_MASTER,
        name: 'Employee Master',
    },
    [MODULES_ENUM.AUTH]: {
        path: '/auth',
        id: MODULES_ENUM.AUTH,
        name: 'Auth',
    },
    [MODULES_ENUM.INSTANT_REWARDS]: {
        path: '/instant-rewards',
        id: MODULES_ENUM.INSTANT_REWARDS,
        name: 'InstantRewards',
    },
});

export const BASE_URL = Object.freeze({
    STAGE: 'https://api.expense.mum1-stage.zeta.in',
    PREPROD: 'https://api.expense.mum1-pp.zeta.in',
    PROD: 'https://api.expense.mum1.zeta.in',
    PROD_UK: 'https://api.expense.mum1.zeta.in',
});

export const pages = Object.freeze({
    BenefitsLandingPage: {
        name: 'BenefitsLandingPage',
    },
    BenefitsContractDetailPage: {
        name: 'BenefitsContractDetailPage',
    },
    VoucherLandingPage: {
        name: 'VoucherLandingPage',
    },
    VoucherPastOrderPage: {
        name: 'VoucherPastOrderPage',
    },
    LandingPage: {
        name: 'RecognitionLandingPage',
    },
    AudiencePreviewPage: {
        name: 'AudiencePreviewPage',
    },
    CreateProgramPage: {
        name: 'CreateProgramPage',
    },
    EmployeeMasterLandingPage: {
        name: 'EmployeeMasterLandingPage',
    },
    BulkEmployeePage: {
        name: 'BulkEmployeePage',
    },
    BulkEmployeeListPage: {
        name: 'BulkEmployeeListPage',
    },
    OrderDetailsPage: {
        name: 'OrderDetailsPage',
    },
    ViewEmployeePage: {
        name: 'ViewEmployeePage',
    },
    EditEmployeePage: {
        name: 'EditEmployeePage',
    },
    AddEmployeePage: {
        name: 'AddEmployeePage',
    },
    LoginPage: {
        name: 'AUTH_LoginPage',
    },
    InstantRewardsLandingPage: {
        name: 'InstantRewardsLandingPage',
    },
    AllCategoriesPage: {
        name: 'AllCategoriesPage',
    },
    CategoryPage: {
        name: 'CategoryPage',
    },
    InstantRewardsBasketPage: {
        name: 'InstantRewardsBasketPage',
    },
    SubcategoryPage: {
        name: 'SubcategoryPage',
    },
    VoucherDetailsPage: {
        name: 'VoucherDetailsPage',
    },
    IrOrderHistoryPage: {
        name: 'IrOrderHistoryPage',
    },
    IrOrderDetailsPage: {
        name: 'IrOrderDetailsPage',
    },
    InstantRewardsOrderSummaryPage: {
        name: 'InstantRewardsOrderSummaryPage',
    },
});

export const PROFILE_ITEMS = Object.freeze({
    VIEW_PROFILE: {
        name: 'View Profile',
        path: '/user-profile',
        image: require('@/assets/profile.svg'),
    },
    HELP_AND_SUPPORT: {
        name: 'Help and Support',
        path: '/user',
        image: require('@/assets/help.svg'),
    },
    LOGOUT: {
        name: 'Logout',
        path: '/user',
        icon: 'logout',
    },
    TC: {
        name: 'Terms & Conditions',
        path: 'https://sodexo-engage.com/p/48OZ-JJQ/hyse-tcs',
        icon: 'file',
        isExternal: true,
    },
    PRIVACY_POLICY: {
        name: 'Privacy policy',
        path: 'https://www.smsbruk.co.uk/',
        icon: 'information',
        isExternal: true,
    },
    COOKIE: {
        name: 'Cookies Settings',
        path: '/#',
        icon: 'cookie',
    },
});

export const EMPLOYEE_STATUS_OPTIONS: any = {
    ACTIVE: {
        label: 'Active',
        value: 'ACTIVE',
        subtext:
            'Employee has all access and is eligible for awards and points',
    },
    PAUSED: {
        label: 'Pause',
        value: 'PAUSED',
        subtext:
            'Employee has no access and points are frozen until activated again',
    },
    INACTIVE: {
        label: 'Inactive',
        value: 'INACTIVE',
        subtext: 'Employee access is blocked and all points are revoked',
    },
};
export const EMPLOYEE_EMPLOYMENT_TYPE_OPTIONS: any = [
    {
        label: 'Full Time',
        value: 'FULLTIME',
    },
    {
        label: 'Part-Time',
        value: 'PARTTIME',
    },
    {
        label: 'Contractor',
        value: 'CONTRACTOR',
    },
    {
        label: 'Intern',
        value: 'INTERN',
    },
];

export const EMPLOYEE_SALUTATION_OPTIONS: any = [
    {
        value: 'MR',
        label: 'Mr.',
    },
    {
        value: 'MRS',
        label: 'Mrs.',
    },
    {
        value: 'MISS',
        label: 'Miss',
    },
    {
        value: 'MS',
        label: 'Ms',
    },
    {
        value: 'DR',
        label: 'Dr',
    },
    {
        value: 'SIR',
        label: 'Sir',
    },
];
export const DEFAULT_PER_PAGE_OPTIONS: any = [
    {
        text: '5 per page',
        value: 5,
    },
    {
        text: '10 per page',
        value: 10,
    },
    {
        text: '20 per page',
        value: 20,
    },
];
export const BULK_EMPLOYEE_FILE_SAMPLES = [
    {
        type: 'csv',
        icon: require('@/assets/file-icons/csv.svg'),
        link: '/static/bulk-upload-samples/sample.csv',
    },
    // {
    //     type: 'xls',
    //     icon: require('@/assets/file-icons/xls.svg'),
    //     link: 'bulk-upload-samples/sample.xls',
    // },
    // {
    //     type: 'xlsx',
    //     icon: require('@/assets/file-icons/xlsx.svg'),
    //     link: 'bulk-upload-samples/sample.xlsx',
    // },
];
export const INSTANT_REWARDS_DISTIBUTION_SAMPLES = [
    {
        type: 'csv',
        icon: require('@/assets/file-icons/csv.svg'),
        link: '/static/instant-rewards/distribution-sample.csv',
    },
    // {
    //     type: 'xls',
    //     icon: require('@/assets/file-icons/xls.svg'),
    //     link: 'bulk-upload-samples/sample.xls',
    // },
    // {
    //     type: 'xlsx',
    //     icon: require('@/assets/file-icons/xlsx.svg'),
    //     link: 'bulk-upload-samples/sample.xlsx',
    // },
];
export const STATUS_TAG_MAP: { [k: string]: any } = {
    active: 'success',
    paused: 'warning',
    inactive: 'danger',
    success: 'success',
    failed: 'danger',
    processing: 'primary',
    completed: 'success',
    cancelled: 'danger',
    validating: 'primary',
    initiated: 'primary',
    error: 'danger',
    scheduled: 'primary',
    invalid: 'danger',
    duplicate: 'danger',
    pending_registration: 'primary',
    bulk_order_validation_failed: 'danger',
    created: 'primary',
    queued: 'primary',
    pending: 'primary',
    partial_success: 'warning',
    expired: 'danger',
    award_approved: 'success',
    award_rejected: 'danger',
    nomination_approval_pending: 'warning',
    nomination_approved: 'success',
    nomination_rejected: 'danger',
    nomination_auto_rejected: 'danger',
};
export const STATUS_TEXT_MAP: { [k: string]: any } = {
    bulk_order_validation_failed: 'failed',
    partial_success: 'partial success',
    award_approved: 'approved',
    award_rejected: 'declined',
    nomination_approval_pending: 'pending',
    nomination_approved: 'approved',
    nomination_rejected: 'declined',
    nomination_auto_rejected: 'auto-declined',
};
export const STATUS_MODAL_TEXT: { [k: string]: any } = {
    INACTIVE: `The employee will not be able to Redeem earned points and Issue new
        awards as their ‘Awarding Balance’ and ‘Your Balance’ will be
        revoked and any pending nominations will be auto declined. This
        action cannot be reversed`,
    ACTIVE: ` The activated employee will be a new employee with the same email ID
    and team structure. Once Activated the Admin will be able to update
    employee details (Groups, BU, Team) as required.`,
};
export const EMPLOYEE_FIELD_MAP: { [k: string]: any } = {
    employeeBusinessEmail: 'Employee’s email address',
    employeeId: 'Employee ID',
    username: 'Username',
    title: 'Title',
    employeeFirstName: 'First name',
    employeeMiddleName: 'Middle name',
    employeeLastName: 'Last name',
    accountStatus: 'Account status',
    employeeContractType: 'Contract type',
    dob: 'Date of birth',
    doh: 'Date of hire',
    jobTitle: 'Job title',
    jobTitleId: 'Job title ID',
    jobGrade: 'Job grade',
    companyName: 'Company name',
    businessUnit: 'Business unit',
    businessUnitId: 'Business unit ID',
    country: 'Country',
    department: 'Department',
    departmentId: 'Department ID',
    teamName: 'Team name',
    teamId: 'Team ID',
    managerName: "Manager's name",
    managerEmail: "Manager's email",
    costCenter: 'Cost center',
    employeeGroup: 'Linked groups',
    profileType: 'Profile type',
};

export const THEME_ID_TO_BUISNESS_THEME_NAME_MAP: any = Object.freeze({
    TH001: 'sodexo-uk-midnight',
    TH002: 'sodexo-uk-azure',
    TH003: 'sodexo-uk-scarlet',
    TH004: 'sodexo-uk-mint',
    TH005: 'sodexo-uk-amethyst',
    TH006: 'sodexo-uk-amberglow',
});

export const BUSINESS_COMPONENTS: any = {
    ACCESS_CONTROL: {
        LIST: {
            id: 'zwe-view-accesses-list',
            src:
                'https://banking-assets.s3.ap-south-1.amazonaws.com/zeta-business/access-management/0.4.22/zwe-view-accesses-list/bundle.js',
        },
        GRANT_ACCESS: {
            id: 'zwe-grant-new-access',
            src:
                'https://banking-assets.s3.ap-south-1.amazonaws.com/zeta-business/access-management/0.4.23/zwe-grant-new-access/bundle.js',
        },
    },
};
