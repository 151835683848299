import WebStorage from '@/commons/web.localStorage';
import store from '../store';
import {
    BASKET_KEY,
    BasketData,
    BasketItem,
    BasketManagement,
} from './basket.types';

function isBasketEmpty() {
    if (!WebStorage.hasItem(BASKET_KEY)) {
        return true;
    }
    return false;
}

function _updateBasket(basketData: BasketData) {
    // adding up the total for finalAmount, totalAmount, totalDiscount
    const updatedTotal = basketData.items.reduce(
        (basketSumAccum: any, basketItem: BasketItem) => {
            basketSumAccum.finalAmount += basketItem.finalAmount;
            basketSumAccum.totalAmount += basketItem.totalAmount;
            basketSumAccum.totalDiscount += basketItem.totalDiscount;
            return basketSumAccum;
        },
        { finalAmount: 0, totalAmount: 0, totalDiscount: 0 }
    );
    const updatedBasket = { ...basketData, ...updatedTotal };
    (store as any).state.basketHeader = {
        basketCount: updatedBasket.items.length,
        basketTotalAmount: updatedBasket.finalAmount,
    };
    WebStorage.setItem(BASKET_KEY, updatedBasket);
}

function _initBasketData() {
    const basket: BasketData = {
        items: [],
        finalAmount: 0,
        totalAmount: 0,
        totalDiscount: 0,
    };

    _updateBasket(basket);
}

function getBasketData(): BasketData {
    if (isBasketEmpty()) {
        _initBasketData();
    }
    const basket: BasketData = (WebStorage.getItem(
        BASKET_KEY
    ) as unknown) as BasketData; //WebStorage.getItem returns object but in its type its mentioned "string | null"
    return basket;
}

function _isItemAlreadyInBasket(itemData: BasketItem): boolean {
    const basket = getBasketData();
    return !!basket.items.find(basketItem => {
        return (
            itemData.id === basketItem.id &&
            itemData.denominationValue === basketItem.denominationValue
        );
    });
}

function parseToBasketItem(itemDetails: BasketItem): BasketItem {
    return {
        ...itemDetails,
        denominationCurrencyId:
            itemDetails.denominationCurrencyId ||
            itemDetails?.denominationCurrency?.id ||
            itemDetails?.salePriceCurrency?.id,
        warehouseProductId: itemDetails.warehouseProductId || itemDetails.code,
        status: 'ENABLED',
    };
}

function addItemToBasket(itemData: BasketItem) {
    const basket = getBasketData();
    if (_isItemAlreadyInBasket(itemData)) {
        const basketItem: BasketItem | null =
            basket.items.find(basketItem => {
                return (
                    itemData.id === basketItem.id &&
                    itemData.denominationValue === basketItem.denominationValue
                );
            }) || null;
        if (basketItem) {
            basketItem.quantity = +basketItem.quantity + +itemData.quantity;
            basketItem.totalAmount =
                basketItem.quantity * itemData.denominationValue;
            const savings =
                basketItem.quantity *
                Math.ceil(
                    (itemData.denominationValue *
                        itemData.discountDetails.discountValue) /
                        100
                );
            basketItem.finalAmount = basketItem.totalAmount - savings;
        }
    } else {
        basket.items.push(parseToBasketItem(itemData));
    }

    _updateBasket(basket);
}

function clearBasket() {
    _initBasketData();
}

function removeItemFromBasket(itemData: BasketItem) {
    if (!_isItemAlreadyInBasket(itemData)) {
        return false;
    }
    const basket = getBasketData();
    basket.items = basket.items.filter(item => {
        if (
            item.id == itemData.id &&
            item.denominationValue == itemData.denominationValue
        ) {
            return false;
        }
        return true;
    });
    _updateBasket(basket);
}

function getBasketCount(): number {
    return getBasketData()?.items?.length || 0;
}

function updateFullBasket(updatedBasketItems: BasketItem[]) {
    updatedBasketItems = updatedBasketItems.map(parseToBasketItem);

    const basket: BasketData = {
        items: updatedBasketItems,
        finalAmount: 0,
        totalAmount: 0,
        totalDiscount: 0,
    };

    _updateBasket(basket);
}

const basketManager: BasketManagement = {
    addItemToBasket,
    clearBasket,
    removeItemFromBasket,
    getBasketData,
    getBasketCount,
    updateFullBasket,
    parseToBasketItem,
};

export default basketManager;
