import { MutationTree } from 'vuex';
import { RootState } from './types';
export const INITIALISE_STATE = 'initialiseState';
export const UPDATE_SIDEBAR = 'updateSidebar';
export const UPDATE_USER_DETAIL = 'updateUserDetail';
export const UPDATE_ACCOUNT_HOLDER_DETAILS = 'updateAccountHolderDetails';
export const UPDATE_SIDEBAR_VISIBILITY = 'updateSidebarVisibility';
export const UPDATE_COMPANY_DETAIL = 'updateCompanyDetail';
export const UPDATE_COMPANY_ATTRIBUTES = 'updateCompanyAttributes';
export const UPDATE_BUSINESS_THEME = 'updateBusinessTheme';
export const UPDATE_NOSIDEBAR = 'updateNoSidebar';
const mutations: MutationTree<RootState> = {
    [INITIALISE_STATE](state, payload) {
        state = Object.assign(state, payload);
    },
    [UPDATE_USER_DETAIL](state, payload: string) {
        if (state.userDetail) {
            state.userDetail = Object.assign({}, state.userDetail, payload);
        } else {
            state.userDetail = payload;
        }
    },
    [UPDATE_ACCOUNT_HOLDER_DETAILS](state, payload: string) {
        if (state.accountHolderDetails) {
            state.accountHolderDetails = Object.assign(
                {},
                state.accountHolderDetails,
                payload
            );
        } else {
            state.accountHolderDetails = payload;
        }
    },
    [UPDATE_COMPANY_DETAIL](state, payload) {
        if (payload.compId) state.compId = payload.compId;
        if (payload.compId) state.corpId = payload.corpId;
        if (payload.compId) state.espId = payload.espId;
        if (payload.tenantId) state.tenantId = payload.tenantId;
        if (payload.subTenantId) state.subTenantId = payload.subTenantId;
    },
    [UPDATE_COMPANY_ATTRIBUTES](state, payload) {
        state.companyAttributes = payload;
    },
    [UPDATE_BUSINESS_THEME](state, payload) {
        state.businessTheme = payload;
    },
    [UPDATE_NOSIDEBAR](state, payload: boolean) {
        state.noSidebar = payload;
    },
};

export default mutations;
