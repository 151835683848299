import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { RootState } from '@/store/types';
import actions from './employeeMaster.actions';
import getters from './employeeMaster.getters';
import mutations from './employeeMaster.mutations';
import state from './../store/employeeMaster.state';

import { EmployeeMasterState } from './../store/employeeMaster.state';

const module: Module<EmployeeMasterState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export const EMPLOYEE_MASTER_STORE = 'employeeMaster';

export const EmployeeMasterModule = namespace(EMPLOYEE_MASTER_STORE);

export default module;
