import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { RootState } from '@/store/types';
import actions from './benefits.actions';
import getters from './benefits.getters';
import mutations from './benefits.mutations';
import state from './benefits.state';

import { BenefitState } from './benefits.types';

const module: Module<BenefitState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export const BENEFITS_STORE = 'benefits';

export const BenefitsModule = namespace(BENEFITS_STORE);

export default module;
