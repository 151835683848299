import { RootState } from './types';
import { PROFILE_ITEMS } from '@/commons/constants';

const state: RootState = {
    sidebarItemsList: [],
    sidebarProfileItemsList: [
        PROFILE_ITEMS.TC,
        PROFILE_ITEMS.PRIVACY_POLICY,
        PROFILE_ITEMS.COOKIE,
        PROFILE_ITEMS.LOGOUT,
    ],
    userDetail: null,
    accountHolderDetails: null,
    noSidebar: false,
    companyAttributes: {
        logo: '',
        firtTimeLogin: false,
        themes: [],
    },
    businessTheme: '',
};

// FO -
// Administration_Admin : Manage Employee Master, Admin Console, Recognition, Reports, Insights
// Product_Admin : Recognition Programs, Awards and Budget allocation, Reports, Insights

export const USER_DETAIL = `userDetail`;
export default state;
