
import { Vue, Component, Prop } from 'vue-property-decorator';

import Layout from '@/components/Layout/AuthLayout.vue';
import Snackbar from '@/components/Snackbar.vue';
import LoadingPage from '@/components/LoadingPage.vue';
import ErrorPage from '@/components/ErrorPage.vue';
@Component({ components: { Layout, Snackbar, LoadingPage, ErrorPage } })
export default class DefaultLayoutWrapper extends Vue {
    @Prop({ default: false }) private showError!: boolean;
    @Prop({ default: false }) private userDataReady!: boolean;
}
