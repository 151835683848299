export const BASKET_KEY = 'IR_BASKET';

export interface BasketItem {
    id: number;
    denominationValue: number;
    finalAmount: number;
    quantity: number;
    totalAmount: number;
    totalDiscount: number;
    [key: string]: any;
}

export interface BasketData {
    items: BasketItem[];
    finalAmount: number;
    totalAmount: number;
    totalDiscount: number;
}

export interface BasketManagement {
    addItemToBasket: Function;
    clearBasket: Function;
    removeItemFromBasket: Function;
    getBasketData: Function;
    getBasketCount: Function;
    updateFullBasket: Function;
    parseToBasketItem: Function;
}
