import { RouteConfig } from "vue-router";
import { MODULES, pages } from "@/commons/constants";

const BenefitsPage = () =>
  import(
    /* webpackChunkName: "benefitPage" */ "@/modules/benefits/Benefits.vue"
  );
const BenefitsLandingPage = () =>
  import(
    /* webpackChunkName: "benefitLandingPage" */ "@/modules/benefits/pages/BenefitsLandingPage.vue"
  );
const BenefitsContractDetailPage = () =>
  import(
    /* webpackChunkName: "benefitsContractDetailPage" */ "@/modules/benefits/pages/BenefitsContractDetailPage.vue"
  );

const routes: RouteConfig[] = [
  {
    path: MODULES.BENEFITS.path,
    component: BenefitsPage,
    children: [
      {
        path: "",
        name: pages.BenefitsLandingPage.name,
        component: BenefitsLandingPage,
      },
      {
        path: "contract-details",
        name: pages.BenefitsContractDetailPage.name,
        component: BenefitsContractDetailPage,
      },
    ],
  },
];

export default routes;
