import { namespace } from 'vuex-class';

export interface InstantRewardsState {
    [key: string]: any;
    instantRewardsBalance: any;
    basketHeader: {
        basketCount: number;
        basketTotalAmount: number;
    };
}

const state: InstantRewardsState = {
    instantRewardsBalance: null,
    marketplaceProvider: null,
    basketHeader: {
        basketCount: -1,
        basketTotalAmount: 0,
    },
};

export default state;

export const INSTANT_REWARDS_STORE = 'instantRewards';

export const InstantRewardsModule = namespace(INSTANT_REWARDS_STORE);
