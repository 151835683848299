import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { RecognitionState } from './recognition.state';
import {
    FETCH_AUDIENCE_LIST,
    FETCH_PROGRAM_LIST,
    CREATE_PROGRAM,
    CREATE_BADGE,
    FETCH_PROGRAM,
    FETCH_BADGES,
    FETCH_BADGE,
    DELETE_BADGE,
    GET_GROUPS,
    FETCH_PRODUCT_CONFIG,
    FETCH_BADGE_PRESETS,
    FETCH_PROGRAM_PRESETS,
    FETCH_CURRENCY_LIST,
    FETCH_BUDGET_HOLDER_LIST,
    FETCH_BUDGET_SUMMARY,
    FETCH_AWARDS_ISSUANCE_LIST,
    CREATE_ALLOCATE_BUDGET_ORDER,
    FETCH_RECOGNITION_ACCOUNT_BALANCE,
    FETCH_ALLOCATION_HISTORY,
    FETCH_ALLOCATION_ORDER,
    FETCH_AWARD_REPORT,
    FETCH_ALL_PROGRAM_AUDIENCE,
    GET_GROUP,
    GET_GROUP_BY_GROUP_ID_LIST,
    FETCH_AWARD_DISTRUBUTION,
    CREATE_ALLOCATE_BUDGET_ORDER_BULK,
    FETCH_EMPLOYEES,
    FETCH_AWARD_ISSUANCE_SUMMARY,
    FETCH_AWARD_ISSUANCE_DETAILS,
    FETCH_BADGE_REPORT,
} from './recognition.actions.types';
import { audienceList, programList } from './dummyData';
import RecognitionService from '@/services/recognition';
import RewardsSevice from '@/services/rewards';
import CorpCoreService from '@/services/corpcore';
import { EventBus, Events } from '@/commons/eventBus';
//import { dummyUsers } from '@/commons/constants';
import {
    UPDATE_PROGRAM_DETAIL,
    UPDATE_BADGES,
    RESET_PROGRAM_DETAIL,
    UPDATE_PRODUCT_CONFIG,
    UPDATE_GROUPS,
    UPDATE_AWARD_PRESETS,
    UPDATE_PROGRAM_PRESETS,
    UPDATE_CURRENCY_LIST,
    UPDATE_RECOGNITION_BALANCE,
    UPDATE_PROGRAM_AUDIENCE,
} from './recognition.mutations';

const actions: ActionTree<RecognitionState, RootState> = {
    async [FETCH_PRODUCT_CONFIG]({ rootState, commit }, extraParams = {}) {
        // console.log("rootState ==>", rootState);
        return new Promise(resolve => {
            const params = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
                ...extraParams,
            };
            RecognitionService.getProductConfig(params).then(
                resp => {
                    //   EventBus.$emit(Events.hideLoader);
                    const rules: any = {};
                    const sections: any[] = resp.data.data.sections;
                    for (let index = 0; index < sections.length; index++) {
                        const section = sections[index];
                        for (let i = 0; i < section.subSections.length; i++) {
                            const subSection = section.subSections[i];
                            const articles = subSection.articles;
                            Object.keys(articles).forEach((key: string) => {
                                rules[key] = articles[key];
                            });
                        }
                    }
                    commit(UPDATE_PRODUCT_CONFIG, rules);
                    resolve(resp.data);
                },
                err => {
                    //   EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
            // setTimeout(() => {
            //     resolve({
            //         entities: dummyUsers,
            //         total: dummyUsers.length,
            //     });
            // }, 1000);
        });
    },
    async [FETCH_EMPLOYEES]({ rootState }, params) {
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            };
            CorpCoreService.fetchEmployees(pathParams, {
                ...params,
                state: 'ACTIVE, PAUSED, INACTIVE',
            }).then(
                resp => {
                    resolve(resp.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_ALL_PROGRAM_AUDIENCE](
        { rootState, commit, state, dispatch },
        pageNo = 0
    ) {
        // console.log("rootState ==>", rootState);
        if (pageNo == 0) {
            const audienceMap = state.audience;
            const size = Object.keys(audienceMap).length;
            if (size > 0) return true;
        }

        //if()
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            };
            const groupId = state.programDetail.audience.groups[0].groupId;
            const params = {
                pageSize: 25,
                pageNumber: pageNo,
                groupIDList: groupId,
                state: 'ACTIVE, PAUSED, INACTIVE',
            };
            CorpCoreService.fetchEmployees(pathParams, params).then(
                async resp => {
                    //   EventBus.$emit(Events.hideLoader);
                    const list: any[] = resp.data.employees;
                    const audienceMap: any = {};
                    list.forEach(item => {
                        audienceMap[item.employeeID] = item;
                    });
                    commit(UPDATE_PROGRAM_AUDIENCE, audienceMap);
                    if (resp.data.hasNext) {
                        const nextPage = pageNo + 1;
                        //    console.log("nextPage", nextPage);
                        await dispatch(FETCH_ALL_PROGRAM_AUDIENCE, nextPage);
                    }
                    resolve(resp.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_AWARD_REPORT]({ rootState, commit, state }, extraParams = {}) {
        // console.log("rootState ==>", rootState);
        return new Promise(resolve => {
            const params = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                id: state.programDetail.id,
                tenantId: rootState.tenantId,
            };
            RecognitionService.fetchAwardReport(params, {
                status: 'AWARD_APPROVED',
                audienceType: 'RECOGNIZER',
                participantIds: '',
                ...extraParams,
            }).then(
                resp => {
                    resolve(resp.data.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_AUDIENCE_LIST]({ rootState }, params) {
        // console.log("rootState ==>", rootState);
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            };
            CorpCoreService.fetchEmployees(pathParams, params).then(
                resp => {
                    //   EventBus.$emit(Events.hideLoader);
                    resolve(resp.data);
                },
                err => {
                    //   EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
            // setTimeout(() => {
            //     resolve({
            //         entities: dummyUsers,
            //         total: dummyUsers.length,
            //     });
            // }, 1000);
        });
    },
    async [GET_GROUPS]({ rootState, commit }, params) {
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            };
            CorpCoreService.getGroups(pathParams, params).then(
                resp => {
                    commit(UPDATE_GROUPS, resp.data.entries);
                    resolve(resp.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [GET_GROUP]({ rootState, commit }, params) {
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
                id: params.id,
            };
            CorpCoreService.getGroup(pathParams, {}).then(
                resp => {
                    //  commit(UPDATE_GROUPS, resp.data.entries);
                    resolve(resp.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },

    async [GET_GROUP_BY_GROUP_ID_LIST]({ rootState, commit }, params) {
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            };
            CorpCoreService.getGroupByGroupIdList(pathParams, {
                ...params,
            }).then(
                resp => {
                    //  commit(UPDATE_GROUPS, resp.data.entries);
                    resolve(resp.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },

    async [FETCH_PROGRAM_LIST]({ rootState, commit }, params) {
        commit(RESET_PROGRAM_DETAIL);
        EventBus.$emit(Events.showLoader);
        const { tenantId, espId, corpId, compId } = rootState;

        return new Promise(resolve => {
            RecognitionService.fetchProgramList(
                {
                    tenantId,
                    espId,
                    corpId,
                    compId,
                },
                { ...params }
            ).then(
                resp => {
                    resolve(resp.data);
                    EventBus.$emit(Events.hideLoader);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [CREATE_PROGRAM]({ rootState }, payload) {
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        EventBus.$emit(Events.showLoader);
        //To be removed
        const createdBy = rootState.userDetail?.employeeID;
        return new Promise(resolve => {
            RecognitionService.createProgram(pathParams, {
                ...payload,
                createdBy, //Temporaray hack
            }).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    resolve(resp.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [CREATE_ALLOCATE_BUDGET_ORDER]({ rootState, state }, payload) {
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            programId: state.programDetail.id,
            tenantId: rootState.tenantId,
        };
        EventBus.$emit(Events.showLoader);
        return new Promise(resolve => {
            RecognitionService.allocateBudget(pathParams, payload).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    resolve(resp.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [CREATE_ALLOCATE_BUDGET_ORDER_BULK]({ rootState, state }, payload) {
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            programId: state.programDetail.id,
        };
        EventBus.$emit(Events.showLoader);
        return new Promise(resolve => {
            RecognitionService.allocateBudgetBulk(pathParams, payload).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    resolve(resp.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_ALLOCATION_HISTORY]({ rootState, state }, params) {
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            programId: state.programDetail.id,
            tenantId: rootState.tenantId,
        };
        EventBus.$emit(Events.showLoader);
        return new Promise(resolve => {
            RecognitionService.getAllocationHistory(pathParams, params).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_ALLOCATION_ORDER]({ rootState, state }, payload) {
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            programId: state.programDetail.id,
            id: payload,
        };
        EventBus.$emit(Events.showLoader);
        return new Promise(resolve => {
            RecognitionService.getAllocationOrder(pathParams).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_PROGRAM]({ rootState, commit }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        return new Promise(resolve => {
            RecognitionService.fetchProgram({
                id: payload,
                ...pathParams,
            }).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    commit(UPDATE_PROGRAM_DETAIL, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_AWARDS_ISSUANCE_LIST]({ rootState, commit, state }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            id: state.programDetail.id,
        };
        return new Promise(resolve => {
            RecognitionService.fetchAwardIssuanceList(pathParams, payload).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    // commit(UPDATE_PROGRAM_DETAIL, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [CREATE_BADGE]({ rootState }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        //To be removed
        const createdBy = rootState.userDetail?.employeeID;
        return new Promise(resolve => {
            RecognitionService.createBadge(pathParams, {
                ...payload,
                createdBy, //Temporaray hack
            }).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    resolve(resp.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_BADGES]({ rootState, commit, state }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        const { espId, corpId, compId, id: programId } = state.programDetail;
        return new Promise(resolve => {
            RecognitionService.getBadges(pathParams, {
                programId,
            }).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    commit(UPDATE_BADGES, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_BADGE_PRESETS]({ rootState, commit, state }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        return new Promise(resolve => {
            RecognitionService.getBadgePresets(pathParams).then(
                resp => {
                    //resp.data.data.splice(0, 2);
                    EventBus.$emit(Events.hideLoader);
                    commit(UPDATE_AWARD_PRESETS, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_BADGE]({ rootState, commit }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        return new Promise(resolve => {
            RecognitionService.getBadge({ id: payload, ...pathParams }).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    // commit(UPDATE_PROGRAM_DETAIL, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_BADGE_REPORT]({ rootState, commit }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        return new Promise(resolve => {
            RecognitionService.getBadgeReport({
                id: payload,
                ...pathParams,
            }).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    // commit(UPDATE_PROGRAM_DETAIL, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [DELETE_BADGE]({ rootState, commit }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        return new Promise(resolve => {
            RecognitionService.deleteBadge({ id: payload, ...pathParams }).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    // commit(UPDATE_PROGRAM_DETAIL, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_PROGRAM_PRESETS]({ rootState, commit, state }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        return new Promise(resolve => {
            RecognitionService.getProgramPresets(pathParams).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    commit(UPDATE_PROGRAM_PRESETS, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_CURRENCY_LIST]({ rootState, commit, state }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        return new Promise(resolve => {
            RewardsSevice.getCurrencyList(pathParams).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    commit(UPDATE_CURRENCY_LIST, resp.data.data.currencies);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_RECOGNITION_ACCOUNT_BALANCE](
        { rootState, commit, state },
        payload
    ) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        return new Promise(resolve => {
            RewardsSevice.getAccountBalances(pathParams, {
                product: 'SPOTLIGHT',
            }).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    commit(UPDATE_RECOGNITION_BALANCE, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_BUDGET_HOLDER_LIST]({ rootState, commit, state }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            rewardOfferingId: state.programDetail.rewardOfferingId,
            id: state.programDetail.id,
            tenantId: rootState.tenantId,
        };
        return new Promise(resolve => {
            RewardsSevice.getBudgetHolderList(pathParams, payload).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    //  commit(UPDATE_CURRENCY_LIST, resp.data.data);
                    const empIds = resp.data.data.entities.map((item: any) => {
                        return item.budgetHolder.entity.id;
                    });
                    RecognitionService.fetchAwardReport(
                        { ...pathParams, tenantId: rootState.tenantId },
                        {
                            status: 'AWARD_APPROVED',
                            audienceType: 'RECOGNIZER',
                            participantIds: empIds.join(','),
                        }
                    ).then(rewRes => {
                        const {
                            employeeAwardCount,
                            employeeBadgeWiseAwardDetail,
                        } = rewRes.data.data;
                        const { entities, totalCount } = resp.data.data;
                        const list = entities.map((s: any) => {
                            const {
                                amountAllocated,
                                amountIssued,
                                amountRevoked,
                                budgetHolder,
                                currentBalance,
                            } = s;
                            const id = budgetHolder.entity.id;
                            const issuedAwards =
                                employeeBadgeWiseAwardDetail[id] || [];
                            const awardCount = employeeAwardCount[id] || 0;
                            return {
                                amountAllocated,
                                amountIssued,
                                amountRevoked,
                                currentBalance,
                                awardCount,
                                issuedAwards,
                                ...budgetHolder.entity,
                            };
                        });
                        resolve({ entities: list, totalCount });
                    });
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_BUDGET_SUMMARY]({ rootState, commit, state }, payload) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            rewardOfferingId: state.programDetail.rewardOfferingId,
            tenantId: rootState.tenantId,
        };
        return new Promise(resolve => {
            RewardsSevice.getBudgetSummary(pathParams, payload).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    //     commit(UPDATE_CURRENCY_LIST, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_AWARD_DISTRUBUTION]({ rootState, commit, state }, params) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            programId: state.programDetail.id,
        };
        return new Promise(resolve => {
            RecognitionService.getAwardDistibution(pathParams, params).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    //     commit(UPDATE_CURRENCY_LIST, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_AWARD_ISSUANCE_SUMMARY]({ rootState, commit, state }, params) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            id: state.programDetail.id,
        };
        return new Promise(resolve => {
            RecognitionService.getAwardIssuanceSummary(pathParams, params).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    // commit(UPDATE_PROGRAM_DETAIL, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_AWARD_ISSUANCE_DETAILS]({ rootState, commit, state }, id) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            id: id,
        };
        return new Promise(resolve => {
            RecognitionService.getAwardIssuanceDetails(pathParams).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    // commit(UPDATE_PROGRAM_DETAIL, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
};

export default actions;
