import { MutationTree } from 'vuex';
import { InstantRewardsState } from './instantRewards.state';
import {
    UPDATE_INSTANT_REWARDS_BALANCE,
    SET_MARKETPLACE_PROVIDER,
} from './instantRewards.mutations.types';

const mutations: MutationTree<InstantRewardsState> = {
    [UPDATE_INSTANT_REWARDS_BALANCE](state, data) {
        state.instantRewardsBalance = data;
    },
    [SET_MARKETPLACE_PROVIDER](state, data) {
        state.marketplaceProvider = data;
    },
};
export default mutations;
