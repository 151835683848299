export const getStatus = (statusCode = 'REJECTED') => {
    const statusMap: any = {
        APPROVED: {
            text: 'Approved',
            statusCode,
            type: 'success',
        },
        REJECTED: {
            text: 'Rejected',
            statusCode,
            type: 'danger',
        },
    };
    return statusMap[statusCode];
};
