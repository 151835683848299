import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { VoucherState } from './voucher.types';
import {
    FETCH_VOUCHER_CAROUSEL_LIST,
    FETCH_PAST_ORDER,
} from './voucher.actions.types';
import { causelList, pastOrderList } from './dummyData';
import { getStatus } from '../mappers';

const actions: ActionTree<VoucherState, RootState> = {
    async [FETCH_VOUCHER_CAROUSEL_LIST]({ rootState }) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({
                    ...causelList,
                });
            }, 500);
        });
    },
    async [FETCH_PAST_ORDER]({ rootState }) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({
                    ...pastOrderList,
                    entities: pastOrderList.entities.map(entity => {
                        return {
                            ...entity,
                            statusDetails: getStatus(entity.status),
                        };
                    }),
                });
            }, 500);
        });
    },
};

export default actions;
