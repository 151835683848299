import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';

const LoginPage = () =>
    import(/* webpackChunkName: "LoginPage" */ '@/modules/auth/Login.vue');

const LoginPage2 = () =>
    import(/* webpackChunkName: "LoginPage" */ '@/modules/auth/Login.vue');

const routes: RouteConfig[] = [
    {
        path: MODULES.AUTH.path,
        component: LoginPage2,
        children: [
            {
                path: 'login',
                name: pages.LoginPage.name,
                component: LoginPage,
            },
        ],
    },
];

export default routes;
