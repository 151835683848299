
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class PageContent extends Vue {
    @Prop(String) private title!: string;
    @Prop({ default: '' }) private footerClass!: string;
    @Prop(Boolean) private hasFooter!: boolean;
    @Prop() private breadcrumb!: any[];
    @Prop() private noHeader!: boolean;
    @Prop({ default: false }) private noBorder!: boolean;
}
