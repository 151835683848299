
import { Component, Vue, Watch } from 'vue-property-decorator';
import { isLoggedIn } from '@/utils/auth';
import DefaultLayoutWrapper from '@/DefaultLayoutWrapper.vue';
import AuthLayoutWrapper from '@/AuthLayoutWrapper.vue';
import { INITIALISE_STATE } from '@/store/mutations';
import { Mutation } from 'vuex-class';
@Component({
    components: {
        DefaultLayoutWrapper,
        AuthLayoutWrapper,
    },
})
export default class App extends Vue {
    @Mutation(INITIALISE_STATE)
    private initialiseRootState: any;
    private get isUserLoggedIn() {
        return isLoggedIn();
    }
    @Watch('$route', { immediate: true, deep: true }) private urlChanged() {
        const currentRoute = this?.$route?.name || ''; //need to handle this case
        if (currentRoute.indexOf('AUTH_') < 0 && !this.isUserLoggedIn) {
            this.$router.push({ name: 'AUTH_LoginPage' });
            return;
        }
        if (currentRoute.indexOf('AUTH_') > -1 && this.isUserLoggedIn) {
            this.$router.push('/');
            return;
        }
    }
    private get layout() {
        const currentRoute = this?.$route?.name || ''; //need to handle this case
        if (currentRoute.indexOf('AUTH_') > -1) {
            return 'AUTH';
        }
        return 'DEFAULT';
    }
    private created() {
        const tenantId = window.localStorage.getItem('sessionTenantInfo');
        const domainId = window.localStorage.getItem('sessionDomainInfo');
        const sandboxId = window.localStorage.getItem('sessionSandboxInfo');
        this.initialiseRootState({ tenantId, domainId, sandboxId });
    }
}
