
import { Vue, Component } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { LOG_OUT } from '@/store/actions.types';

import { getEnv } from '@/commons/Utils';
import { EventBus, Events } from '@/commons/eventBus';
import { SidebarItem as SidebarItemType } from '@/store/types';
import SidebarItemGroup from './SidebarItemGroup.vue';
import { PROFILE_ITEMS } from '@/commons/constants';
import { ACTIONS_ENUM, SUBJECTS_ENUM } from '@/commons/accessControl';

import SidebarItem from './SidebarItem.vue';
import { CipherSSO, AuthStore } from '@zeta/authentication';
import { ACCOUNT_HOLDER_DETAILS } from '@/store/getters';
import { serializeObject } from '@/commons/Utils';
@Component({ components: { SidebarItemGroup, SidebarItem } })
export default class Sidebar extends Vue {
    @Action(LOG_OUT) private logoutAction: any;
    @State('sidebarItemsList') private sidebarItemsList!: SidebarItemType[];
    @State('sidebarProfileItemsList') private profileItems!: any[];
    @State('userDetail') private userDetail: any;
    @Getter(ACCOUNT_HOLDER_DETAILS) public accountHolderDetails!: any;
    @State companyAttributes!: any;

    private expandedIndex = -1;
    private displayProfileItems = false;

    private isExpanded(i: number, item: any) {
        return (
            i == this.expandedIndex ||
            item.items.find((nestedItem: any) => {
                return this.$route.path == nestedItem.path;
            })
        );
    }

    private logout() {
        // const sandboxId = AuthStore.getItem('sandbox'),
        //     tenantId = AuthStore.getItem('tenant'),
        //     domainId = AuthStore.getItem('domain');
        // CipherSSO.logout(
        //     `${window.location.origin}/?sandbox=${sandboxId}&tenant=${tenantId}&domain=${domainId}`
        // );
        EventBus.$emit(Events.logout);
    }
    private get productGroupSubtext() {
        let str: any = [];
        if (this.$ability.can('READ', 'RECOGNITION')) {
            str.push('recognition');
        }
        if (this.$ability.can('READ', 'INSTANT_REWARDS')) {
            str.push('instant rewards');
        }
        if (
            this.$ability.can('READ', 'SURVEYS') ||
            this.$ability.can('READ', 'ANNOUNCEMENTS')
        ) {
            str.push('engage hub');
        }

        str = str.join(', ');
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    private get adminGroupSubtext() {
        let str: any = [];
        if (this.$ability.can('READ', 'EMPLOYEE_MASTER')) {
            str.push('employee master');
        }
        if (this.$ability.can('READ', 'REPORT_CENTRE')) {
            str.push('report centre');
        }
        if (this.$ability.can('READ', 'INSIGHTS')) {
            str.push('insights centre');
        }
        if (this.$ability.can('READ', 'ADMIN_CONSOLE')) {
            str.push('admin console');
        }

        str = str.join(', ');
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    private get showProductGroup() {
        return this.$ability.can('READ', 'RECOGNITION');
    }
    private get showAdminstrationGroup() {
        return (
            this.$ability.can('READ', 'EMPLOYEE_MASTER') ||
            this.$ability.can('READ', 'REPORT_CENTRE') ||
            this.$ability.can('READ', 'INSIGHTS') ||
            this.$ability.can('READ', 'ADMIN_CONSOLE')
        );
    }

    private toggle(i: number) {
        if (this.expandedIndex == i) this.expandedIndex = -1;
        else this.expandedIndex = i;
    }
    logFirebaseEvent(eventName: string, productName: string) {
        //  console.log('click event', eventName);
        EventBus.$emit(Events.logFirebaseEvent, eventName, {
            [eventName == 'view_product_FO'
                ? 'product_name'
                : 'module_name']: productName,
        });
    }

    private redirectToFD(i: number) {
        const url =
            getEnv() == 'PROD'
                ? 'https://supporthyse.freshdesk.com/support/home'
                : 'http://testhr.support.sodexo.uk/support/home';
        const params: any = {
            ahId: this.accountHolderDetails.accountHolderId,
            userId: this.userDetail.employeeID,
            email: this.userDetail.email,
            name: this.userDetail.name,
            companyId: this.userDetail.companyID.toString(),
            companyName: this.userDetail.companyName,
        };
        // const serializedparams = Object.keys(params)
        //     .map(function(key: any) {
        //         return key + '=' + params[String(key)];
        //     })
        //     .join('&');
        const serializedparams = serializeObject(params);
        const finalUrl = url + '?' + serializedparams;
        window.open(finalUrl, '_blank');
    }
    private profileItemClick(w: Event, item: any) {
        if (item.name == 'Cookies Settings') {
            //  this.displayProfileItems = false;
            w.preventDefault();
            (document as any).querySelector('.ot-sdk-show-settings').click();
            setTimeout(() => {
                this.displayProfileItems = false;
            }, 1);
        }
    }

    private created() {
        this.$router.afterEach(() => {
            this.expandedIndex = -1;
            this.displayProfileItems = false;
        });
    }
}
