import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { RootState } from '@/store/types';
import actions from './recognition.actions';
import getters from './recognition.getters';
import mutations from './recognition.mutations';
import state from './recognition.state';

import { RecognitionState } from './recognition.state';

const module: Module<RecognitionState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export const RECOGNITION_STORE = 'recognition';

export const RecognitionModule = namespace(RECOGNITION_STORE);

export default module;
