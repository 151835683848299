
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SidebarItem extends Vue {
    @Prop({
        default: 'div',
        validator: (value:string) => {
            return ['div', 'a', 'router-link'].indexOf(value) >= 0;
        },
    })
    private tag!: string;

    @Prop({ default: '' }) private label!: string;

    @Prop({ default: '' }) private icon!: string;
}
