import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { InstantRewardsState } from './instantRewards.state';
import { GET_BASKET_HEADER_DATA } from './instantRewards.getters.types';
import BasketManager from '../basket/basketManager';

const getters: GetterTree<InstantRewardsState, RootState> = {
    [GET_BASKET_HEADER_DATA](state) {
        const { basketCount } = state.basketHeader;
        if (basketCount == -1) {
            state.basketHeader = {
                basketCount: BasketManager.getBasketCount(),
                basketTotalAmount: BasketManager.getBasketData().finalAmount,
            };
        }
        return state.basketHeader;
    },
};

export default getters;
