import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

import { LOG_OUT } from "@/store/actions.types";

@Component({})
export default class GlobalFunc extends Vue {
  @Action(LOG_OUT)
  protected logout: any;
}
