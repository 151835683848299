import { AxiosInstance } from 'axios';
//import { getEnv } from '@/commons/Utils';

import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';

// const baseURL: any = {
//     STAGE:
//         'https://spotlight-recognition-spotlight.mum1-stage.zeta.in/spotlight-recognition/1.0',
//     PREPROD:
//         'https://spotlight-recognition-spotlight.mum1-pp.zeta.in/spotlight-recognition/1.0',
//     PROD:
//         'https://spotlight-recognition-spotlight.mum1.zeta.in/spotlight-recognition/1.0',
// };

class RecognitionService {
    private axios: AxiosInstance;

    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
    }
    private get servicePath() {
        return `/spotlight-recognition/1.0`;
    }

    public fetchProgramList(pathParams: any, params: any) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program/list`,
            params,
        });
    }

    public createProgram(pathParams: any, payload: any) {
        return this.axios.post(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program`,
            payload
        );
    }
    public fetchProgram(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program/${pathParams.id}`,
            { params }
        );
    }
    public fetchAwardIssuanceList(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program/${pathParams.id}/award/list`,
            { params }
        );
    }
    public fetchAwardReport(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program/${pathParams.id}/award/report`,
            { params }
        );
    }
    public deleteProgram(pathParams: any, params: any = {}) {
        return this.axios.delete(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program/${pathParams.id}`,
            {
                params,
            }
        );
    }
    public allocateBudget(pathParams: any, payload: any) {
        return this.axios.post(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program/${pathParams.programId}/budget`,
            payload
        );
    }
    public allocateBudgetBulk(pathParams: any, payload: any) {
        return this.axios.post(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program/${pathParams.programId}/budget/bulkAllocation`,
            payload
        );
    }
    public getAllocationHistory(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program/${pathParams.programId}/budget/list`,
            { params }
        );
    }
    public getAllocationOrder(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${
                pathParams.espId
            }/corporate/${pathParams.corpId}/company/${
                pathParams.compId
            }/program/${pathParams.programId}/budget/order/${[pathParams.id]}`,
            { params }
        );
    }
    public getAwardDistibution(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program/${pathParams.programId}/awardDistribution`,
            { params }
        );
    }
    public createBadge(pathParams: any, payload: any) {
        return this.axios.post(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/badge`,
            payload
        );
    }
    public getBadges(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/badge/list`,
            { params }
        );
    }
    public getBadge(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/badge/${pathParams.id}`,
            { params }
        );
    }
    public getBadgeReport(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/badge/${pathParams.id}/report`,
            { params }
        );
    }
    public getBadgePresets(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/badge/images`,
            { params }
        );
    }
    public deleteBadge(pathParams: any, params: any = {}) {
        return this.axios.delete(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/badge/${pathParams.id}`,
            { params }
        );
    }
    public getProductConfig(params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/configuration`
        );
    }
    public getProgramPresets(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/program/images`,
            { params }
        );
    }
    public getAwardIssuanceSummary(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/awardIssuanceSummary`,
            { params }
        );
    }
    public getAwardIssuanceDetails(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/award/${pathParams.id}`,
            { params }
        );
    }
}

const config = {
    baseURL: __APP__.RECOGNITION.BASE_URL,
    resolveAuthToken: () => {
        return localStorage.getItem('@zeta::authToken') || '';
    },
};
export default new RecognitionService(config);
