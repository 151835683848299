
import { Component, Vue } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import { VALID_TILL_KEY, AuthStore } from '@zeta/authentication';

import DefaultLayoutWrapper from '@/DefaultLayoutWrapper.vue';
import AuthLayoutWrapper from '@/AuthLayoutWrapper.vue';
import Layout from '@/components/Layout/DefaultLayout.vue';
import Snackbar from '@/components/Snackbar.vue';
import LoadingPage from '@/components/LoadingPage.vue';
import ErrorPage from '@/components/ErrorPage.vue';

import MultiColorIcons from './MulitColorIcons.vue';
import {
    LOG_OUT,
    FETCH_USER_DETAIL,
    FETCH_COMPANY_ATTRIBUTES,
    FETCH_ACCOUNT_HOLDER_DETAILS,
    FETCH_USER_ROLES,
} from '@/store/actions.types';
import { UPDATE_BUSINESS_THEME } from '@/store/mutations';
import { EventBus, Events } from '@/commons/eventBus';
import { THEME_ID_TO_BUISNESS_THEME_NAME_MAP } from '@/commons/constants';
import Modal from '@/components/Modal.vue';
import updateTheme from '@/utils/theme';
import { Theme } from './store/types';
import { refreshToken, isLoggedIn } from '@/utils/auth';
import onVisibilityChange from '@/utils/visibility';
// import { first } from '@zeta/utils';
import { IdleTimeoutManager } from '@/commons/idleTimeoutManager';
import initPendo from '@/commons/pendo';
import initFirebase from '@/commons/firebase';
import {
    PERMISSION_GROUPS,
    ACTIONS_ENUM,
    SUBJECTS_ENUM,
    INITIAL_ROUTES,
} from '@/commons/accessControl';
const authStore: any = AuthStore;

@Component({
    components: {
        DefaultLayoutWrapper,
        Snackbar,
        LoadingPage,
        ErrorPage,
        Modal,
        AuthLayoutWrapper,
        Layout,
        MultiColorIcons,
    },
})
export default class Wrapper extends Vue {
    @Action(LOG_OUT) private logout: any;
    @Action(FETCH_USER_DETAIL) private fetchUserDetailAction: any;
    @Action(FETCH_COMPANY_ATTRIBUTES) private fetchCompanyAttributes: any;
    @Action(FETCH_ACCOUNT_HOLDER_DETAILS)
    private fetchAccountHolderDetails: any;
    @Action(FETCH_USER_ROLES) private fetchUserRoles: any;
    @Mutation(UPDATE_BUSINESS_THEME)
    private updateBusinessTheme: any;

    @State('userDetail') private userDetail: any;

    // private userDataReady = false; // todo: just making it true for initial setup
    private showError = false; //replace userDataReady
    private loading = true;
    private accessError = false;
    private timeManager: unknown;

    // @Watch('userDetail') private checkUser() {
    //     this.userDataReady = true;
    // }
    private forceLogout() {
        window.localStorage.clear();
        window.location.href = '/auth/login';
    }
    private setIdleTimer() {
        const manager = new IdleTimeoutManager({
            timeout: 30 * 60, //expired after 30 min
            key: '_idleTimeout',
            //events: ["mousemove", "scroll", "keydown"],
            onExpired: (time: any) => {
                console.log(`Expired at ${time}`);
                //this.forceLogout();
                this.logout();
            },
            eventTimeout: 500, //miliseconds
        });
        this.timeManager = manager;
    }
    private async created() {
        initFirebase();
        try {
            const expiryInMs: any =
                authStore.getItem(VALID_TILL_KEY) - Date.now();
            const FIVE_MINUTES = 5 * 60 * 1000;
            console.log(`expiring in ${expiryInMs / (1000 * 60)} minutes`);

            if (expiryInMs < FIVE_MINUTES) {
                console.log('about to expire in 5 mins');
                await refreshToken();
            }
            // logic to logout user after not activity;
            this.setIdleTimer();
            setInterval(() => {
                //Not using set timeout as if the api call fails once at the moment, it will not retry
                const expiryInMs: any =
                    authStore.getItem(VALID_TILL_KEY) - Date.now();
                if (expiryInMs < FIVE_MINUTES) {
                    console.log('#refreshing token');
                    refreshToken().catch(error => console.log('error', error));
                }
            }, FIVE_MINUTES);
            onVisibilityChange((hidden: any) => {
                if (!hidden && !isLoggedIn()) {
                    this.forceLogout();
                }
            });
            EventBus.$on(Events.logout, this.logout);

            await this.fetchUserDetailAction();
            const roles = await this.fetchUserRoles();
            const rolesArray: string[] = [];
            const sandboxIdString = `_${this.$store.state.sandboxId}`;
            roles.forEach((role: any) => {
                rolesArray.push(
                    // roleName: "Dashboard_Viewer_10792" -> DASHBOARD_VIEWER
                    role.roleName.replace(sandboxIdString, '').toUpperCase()
                );
            });
            console.log(rolesArray);
            this.setUserAccesses(rolesArray);

            await this.setDefaultTheme();
            setTimeout(() => {
                initPendo();
            }, 0);
            this.loading = false;

            await this.fetchAccountHolderDetails({
                type: 'LEGAL',
                identifierValue: this.userDetail.companyID,
                identifierProvider: 'CORP_CORE',
                subType: 'COMPANY',
            });
        } catch (e) {
            console.error('SETUP ERROR', e.message);
            if (e.message && String(e.message).indexOf('#LOGOUT') > -1) {
                this.logout();
                return;
            }
            this.loading = false;
            this.showError = true;
        }
    }

    private async setUserAccesses(
        roles: any[] = ['ADMINISTRATION_VIEWER'],
        contract: any[] = [
            SUBJECTS_ENUM.RECOGNITION,
            SUBJECTS_ENUM.EMPLOYEE_MASTER,
            SUBJECTS_ENUM.REPORT_CENTRE,
            SUBJECTS_ENUM.INSIGHTS,
            SUBJECTS_ENUM.ANNOUNCEMENTS,
            //SUBJECTS_ENUM.SURVEYS,
            SUBJECTS_ENUM.BENEFITS,
            SUBJECTS_ENUM.INSTANT_REWARDS,
            SUBJECTS_ENUM.ADMIN_CONSOLE,
            SUBJECTS_ENUM.COMPANY_BALANCE,
            SUBJECTS_ENUM.THEMES,
            SUBJECTS_ENUM.ACCESS_CONTROL,
        ]
    ) {
        const rulesArray: any = [];
        roles.forEach(role => {
            Object.keys(PERMISSION_GROUPS[role]).forEach((sub: string) => {
                //not in contract implies no access given for the feature.
                if (contract.includes(sub)) {
                    if (PERMISSION_GROUPS[role][sub] == ACTIONS_ENUM.READ) {
                        rulesArray.push({
                            action: ACTIONS_ENUM.READ,
                            subject: sub,
                        });
                    } else if (
                        PERMISSION_GROUPS[role][sub] == ACTIONS_ENUM.ACTION
                    ) {
                        rulesArray.push({
                            // if user can ACTION then he can READ as well.
                            action: [ACTIONS_ENUM.READ, ACTIONS_ENUM.ACTION],
                            subject: sub,
                        });
                    }
                }
            });
        });
        // set the ablilty of user, after this function "this.$ability.can(<action_type>, <subject_type>)" will be
        // available across dashboard. eg. this.$ability.can('READ', 'RECOGNITION') will be
        // true if {action:'READ'/['READ', 'ACTION'], subject:'RECOGNITION'} is an element of rulesArray
        await this.$ability.update(rulesArray);
        console.log(this.$ability.rules);
        // Set restriction on route, after this user will not be able to access routes which he should not, Will get access error.
        this.setRouteGuard();
    }

    private setRouteGuard() {
        const isAccessable = (accesses: any[]) => {
            if (!accesses) {
                return true;
            }
            let hasAccess = false;
            accesses.every((el: any) => {
                if (this.$ability.can(el.action, el.subject)) {
                    hasAccess = true;
                    return false;
                }
            });
            return hasAccess;
        };
        // function to addreess redirection from '/' to accessable landing page.
        const redirectToAccessable = () => {
            if (this.$route.path != '/redirect-to-accessable') {
                return '';
            }
            let routeToTransfer = '';
            INITIAL_ROUTES.every(init_route => {
                init_route.accesses.every(access => {
                    if (this.$ability.can(access.action, access.subject)) {
                        routeToTransfer = init_route.route;
                        this.$router.replace(routeToTransfer);
                    }
                    return !routeToTransfer;
                });
                return !routeToTransfer;
            });
            return routeToTransfer;
        };
        //initial load is addressed separately
        redirectToAccessable();
        if (!isAccessable(this.$route.meta?.accesses)) {
            this.accessError = true;
        }

        const routeGuard = (to: any, from: any, next: any) => {
            this.accessError = !isAccessable(to.meta?.accesses);
            next();
            redirectToAccessable();
        };
        this.$router.beforeEach(routeGuard);
    }
    private setDefaultTheme() {
        return this.fetchCompanyAttributes()
            .then((res: any) => {
                const themeColours: any = {};

                const { themes, firstTimeLoggedIn = false } = res;

                const defaultTheme: any = themes.filter((theme: Theme) => {
                    return theme.isDefault;
                })[0];

                Object.keys(defaultTheme.colours).forEach((color: string) => {
                    themeColours[color] = `#${defaultTheme.colours[color]}`;
                });

                updateTheme(themeColours);
                this.updateBusinessTheme(
                    THEME_ID_TO_BUISNESS_THEME_NAME_MAP[defaultTheme.id]
                );

                this.redirectToBrandingSetup(firstTimeLoggedIn);
            })
            .catch((error: any) => console.log('error', error));
    }

    private redirectToBrandingSetup(firstTimeLoggedIn: boolean) {
        if (!firstTimeLoggedIn) {
            this.$router.push('/admin-console/onboarding');
        }
    }
}
