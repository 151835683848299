
import { Vue, Component } from 'vue-property-decorator';

/**
 * 
 #7069df - Primary 80
 #b7b4ef -  Primary 40

 */
@Component({})
export default class MulitcolorIcons extends Vue {}
