import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { RecognitionState } from './recognition.state';

export const AWARD_VIEW = 'awardView';
export const CURRENCY_MAP = 'currencyMap';
export const BALANCE = 'balance';
export const VC_BALANCE = 'vcBalace';
export const CONVERTER = 'converter';
export const PRGORAM_CURRENCY = 'programCurrency';

const getters: GetterTree<RecognitionState, RootState> = {
    [AWARD_VIEW]: state => {
        const programDetail = state.programDetail || {};
        return {
            programId: programDetail.id,
            badges: programDetail.badges,
            name: programDetail.name,
        };
    },
    [CURRENCY_MAP]: state => {
        const currencyList = state.currencyList;
        const map: any = {};
        currencyList.forEach((currency: any) => {
            map[currency.id] = {
                unicode: `${currency.unicodeSymbol.replace('U+', '&#x')};`,
                pluralName: currency.pluralName,
                code: currency.displayCode,
                conversionRatio: Math.round(
                    currency.conversionRatio.toFiatConversionDenominator /
                        currency.conversionRatio.toFiatConversionNumerator
                ),
                id: currency.id,
            };
        });
        return map;
    },
    [BALANCE]: state => {
        return state.balance;
    },
    [VC_BALANCE]: (state, getters) => {
        const map = getters[CURRENCY_MAP];
        const balance = state.balance;
        if (!balance) return null;
        const vcConfig = map[state.programDetail.currency.id];
        //to-do
        return Math.floor((balance / 100) * vcConfig.conversionRatio);
        // return balance ? balance.balance : null;
    },
    [CONVERTER]: (state, getters) => {
        const map = getters[CURRENCY_MAP];
        const vcConfig = map[state.programDetail.currency.id];
        //to-do
        return {
            actualToVC: (actual: number) => {
                return Math.floor((actual / 100) * vcConfig.conversionRatio);
            },
            vCtoActual: (vc: number) => {
                return Math.floor((vc / vcConfig.conversionRatio) * 100);
            },
        };
        // return balance ? balance.balance : null;
    },
    [PRGORAM_CURRENCY]: (state, getters) => {
        const map = getters[CURRENCY_MAP];
        if (!state.programDetail) return {};
        if (state.programDetail.currency.id) {
            return map[state.programDetail.currency.id] || {};
        }
        return {};
    },
};

export default getters;
