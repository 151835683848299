import Vue from 'vue';
import Router from 'vue-router';
import { MODULES, MODULES_ENUM } from '@/commons/constants';
import { ACTIONS_ENUM, SUBJECTS_ENUM } from '@/commons/accessControl';

import BenefitsRoutes from '../modules/benefits/routes';
import VoucherRoutes from '../modules/voucher/routes';
import RecognitionRoutes from '../modules/recognition/routes';
import AdminRoutes from '../modules/admin/routes';
import EmployeeMasterRoutes from '../modules/employeeMaster/routes';
import AuthRoutes from '../modules/auth/routes';
import InstantRewardsRoutes from '../modules/instantRewards/routes';

import EngageHubRoutes from '../modules/engageHub/routes';
import { isLoggedIn } from '@/utils/auth';

const Reports = () =>
    import(/* webpackChunkName: "Reports" */ '../modules/reports/Reports.vue');
const Insights = () =>
    import(
        /* webpackChunkName: "Reports" */ '../modules/insights/Insights.vue'
    );
Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: () => {
                if (isLoggedIn()) {
                    return '/redirect-to-accessable';
                } else {
                    return '/auth/login';
                }
            },
        },
        {
            path: '/report-center',
            name: 'ReportCenter',
            component: Reports,
            meta: {},
        },
        {
            path: '/insights-center',
            name: 'InsightsCenter',
            component: Insights,
        },
        ...EmployeeMasterRoutes,
        ...BenefitsRoutes,
        ...VoucherRoutes,
        ...RecognitionRoutes,
        ...AdminRoutes,
        ...AuthRoutes,
        ...EngageHubRoutes,
        ...InstantRewardsRoutes,
    ],
});

export default router;
