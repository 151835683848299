
import { Component, Vue } from 'vue-property-decorator';
import { EventBus, Events } from '@/commons/eventBus';

@Component({})
export default class Snackbar extends Vue {
    private list: any[] = [];

    private showMessage(data: any) {
        const type = data.type || 'success';
        const duration = data.duration || 3000;
        const position = data.position || 'bottom';
        const id = Date.now();
        setTimeout(() => {
            this.remove(id);
        }, duration);
        this.list.push({
            type,
            duration,
            position,
            message: data.message,
            id,
        });
    }

    private styles(position: string, index: number) {
        let vertical = `top: ${index * 61 + 10}px;`;
        if (position === 'bottom') {
            vertical = `bottom: ${index * 61 + 10}px;`;
        }
        return `${vertical}`;
    }

    private remove(id: number) {
        this.list.splice(
            this.list.findIndex(item => {
                return item.id === id;
            }),
            1
        );
    }

    private mounted() {
        EventBus.$on(Events.showMessage, this.showMessage);
    }
}
