import { RouteConfig } from "vue-router";
import { MODULES, pages } from "@/commons/constants";

const VoucherPage = () =>
  import(
    /* webpackChunkName: "voucherPage" */ "@/modules/voucher/Voucher.vue"
  );
const VoucherLandingPage = () =>
  import(
    /* webpackChunkName: "voucherLandingPage" */ "@/modules/voucher/pages/VoucherLandingPage.vue"
  );
const VoucherPastOrderPage = () =>
  import(
    /* webpackChunkName: "voucherPastOrderPage" */ "@/modules/voucher/pages/VoucherPastOrderPage.vue"
  );

const routes: RouteConfig[] = [
  {
    path: MODULES.VOUCHER.path,
    component: VoucherPage,
    children: [
      {
        path: "",
        name: pages.VoucherLandingPage.name,
        component: VoucherLandingPage,
      },
      {
        path: "past-orders",
        name: pages.VoucherPastOrderPage.name,
        component: VoucherPastOrderPage,
      },
    ],
  },
];

export default routes;
