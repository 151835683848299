export const FETCH_CATEGORY_LIST = 'fetchCategoryList';
export const FETCH_CATEGORY = 'fetchCategory';
export const FETCH_SALEITEMS_LIST = 'fetchSaleItemsList';
export const FETCH_SALEITEM_BY_ID = 'fetchSaleItemById';
export const FETCH_SUBCATEGORY_LIST = 'fetchSubCategoryList';
export const FETCH_SALEITEMS_BY_CATEGORY_ID = 'fetchSaleItemsByCategoryId';
export const FETCH_ORDER_RESOURCE_FOR_USER = 'fetchOrderResourceForUser';
export const FETCH_RESOURCE_DETAILS_BY_ID = 'fetchResourceDetailsById';
export const FETCH_SALEITEMS_FILTERS = 'fetchSaleItemsFilters';
export const FETCH_INSTANT_REWARDS_BALANCE = 'fetchInstantRewardsBalance';
export const VALIDATE_BASKET = 'validateBasket';
export const EVALUATE_BASKET = 'evaluateBasket';
export const FETCH_BILLING_ADDRESS = 'fetchBillingAddress';
export const FETCH_ORDER_HISTORY = 'fetchOrderHistory';
export const FETCH_MARKETPLACE_PROVIDER = 'fetchMarketplaceProvider';
export const FETCH_ORDER_DETAILS_BY_ID = 'fetchOrderDetailsById';
export const DOWNLOAD_DISTRIBUTION_TEMPLATE = 'downloadDistributionTemplate';
export const PLACE_ORDER = 'placeOrder';
export const PLACE_DISTRIBUTION_ORDER = 'placeDistributionOrder';
