import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';
import { ACTIONS_ENUM, SUBJECTS_ENUM } from '@/commons/accessControl';

const EmployeeMasterPage = () =>
    import(
        /* webpackChunkName: "EmployeeMasterPage" */ '@/modules/employeeMaster/EmployeeMaster.vue'
    );
const EmployeeMasterLandingPage = () =>
    import(
        /* webpackChunkName: "EmployeeMasterLandingPage" */ '@/modules/employeeMaster/pages/Home/EmployeeMasterLandingPage.vue'
    );

const BulkEmployee = () =>
    import(
        /* webpackChunkName: "BulkEmployee" */ '@/modules/employeeMaster/pages/AddBulkEmployee/UploadBulkEmployeeFile.vue'
    );
const BulkEmployeeList = () =>
    import(
        /* webpackChunkName: "BulkEmployeeList" */ '@/modules/employeeMaster/pages/AddBulkEmployee/ReviewEmployeeList.vue'
    );

const OrderDetailPage = () =>
    import(
        /* webpackChunkName: "OrderDetailPage" */ '@/modules/employeeMaster/pages/OrderDetails.vue'
    );
const ViewEmployeePage = () =>
    import(
        /* webpackChunkName: "ViewEmployee" */ '@/modules/employeeMaster/pages/ViewEmployee.vue'
    );
const EditEmployeePage = () =>
    import(
        /* webpackChunkName: "EditEmployee" */ '@/modules/employeeMaster/pages/EditEmployee.vue'
    );

const routes: RouteConfig[] = [
    {
        path: MODULES.EMPLOYEE_MASTER.path,
        component: EmployeeMasterPage,
        children: [
            {
                path: '',
                name: pages.EmployeeMasterLandingPage.name,
                component: EmployeeMasterLandingPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.EMPLOYEE_MASTER,
                        },
                    ],
                },
            },
            {
                path: 'bulk',
                name: pages.BulkEmployeePage.name,
                component: BulkEmployee,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.EMPLOYEE_MASTER,
                        },
                    ],
                },
            },
            {
                path: 'orders/:orderId',
                name: pages.OrderDetailsPage.name,
                component: OrderDetailPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.EMPLOYEE_MASTER,
                        },
                    ],
                },
            },
            {
                path: 'bulk/:orderId',
                name: pages.BulkEmployeeListPage.name,
                component: BulkEmployeeList,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.EMPLOYEE_MASTER,
                        },
                    ],
                },
            },
            {
                path: 'add',
                name: pages.AddEmployeePage.name,
                component: EditEmployeePage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.EMPLOYEE_MASTER,
                        },
                    ],
                },
            },
            {
                path: 'employees/:employeeId',
                name: pages.ViewEmployeePage.name,
                component: ViewEmployeePage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.EMPLOYEE_MASTER,
                        },
                    ],
                },
            },
            {
                path: 'employees/:employeeId/edit',
                name: pages.EditEmployeePage.name,
                component: EditEmployeePage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.EMPLOYEE_MASTER,
                        },
                    ],
                },
            },
        ],
    },
];

export default routes;
