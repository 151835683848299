//import { RecognitionState } from './recognition.types';

import { namespace } from 'vuex-class';

export interface EmployeeMasterState {
    employeeData: any;
    employeeFormErrors: any;
    processingEmployeeEditForm: boolean;
    employeeFormTouched: boolean;
}
export const EMPLOYEE_DATA = 'employeeData';
export const EMPLOYEE_FORM_ERRORS = 'employeeFormErrors';
export const PROCESSING_EDIT_EMPLOYEE_FORM = 'processingEmployeeEditForm';
export const EMPLOYEE_FORM_TOUCHED = 'employeeFormTouched';

const state: EmployeeMasterState = {
    employeeData: null,
    employeeFormErrors: {},
    processingEmployeeEditForm: false,
    employeeFormTouched: false,
};

export default state;

export const EMPLOYEE_MASTER_STORE = 'employeeMaster';

export const EmployeeMasterModule = namespace(EMPLOYEE_MASTER_STORE);
