interface Options {
    timeout: number; //seconds
    key: string;
    events: string[];
    eventTimeout?: number; //miliseconds
    onExpired?: (time: any) => void;
}
const defaultOptions: Options = {
    timeout: 10, //seconds
    key: '_timeout',
    events: ['mousemove', 'scroll', 'keydown'],
    eventTimeout: 150, //miliseconds
};
class IdleTimeoutManager {
    options: Options;
    _eventHandlers: any;
    interval = 0;
    _updateGuard = 0;
    _storage: any = localStorage;
    constructor(options: any | Options) {
        this.options = Object.assign(defaultOptions, options);

        this._eventHandlers = this._updateTimeout.bind(this);
        const expiredTime = parseInt(
            this._storage.getItem(this.options.key, 10)
        );
        const currentTime = Date.now();
        if (expiredTime > 0 && expiredTime < currentTime) {
            console.log('already expired');
            this.clear();
            if (this.options.onExpired) {
                this.options.onExpired(expiredTime);
            }
            return;
        }
        this._start();
    }

    _start() {
        this._updateTimeout();
        this._tracker();

        this.interval = setInterval(() => {
            const expiredTime = parseInt(
                this._storage.getItem(this.options.key)
            );
            const currentTime = Date.now();
            if (expiredTime < currentTime) {
                this.clear();
                if (this.options.onExpired) {
                    this.options.onExpired(expiredTime);
                }
            }
        }, 1000);
    }

    _updateTimeout() {
        if (this._updateGuard) {
            clearTimeout(this._updateGuard);
        }

        this._updateGuard = setTimeout(() => {
            this._storage.setItem(
                this.options.key,
                Date.now() + this.options.timeout * 1000
            );
            // console.log(
            //     'udpating idle timeout',
            //     this.options.key,
            //     Date.now() + this.options.timeout * 1000
            // );
        }, this.options.eventTimeout);
    }

    _tracker() {
        for (const event of this.options.events) {
            window.addEventListener(event, this._eventHandlers);
        }
    }

    _clearTracker() {
        for (const event of this.options.events) {
            window.removeEventListener(event, this._eventHandlers);
        }
    }

    clear() {
        localStorage.removeItem(this.options.key);
        if (this.interval) {
            clearInterval(this.interval);
        }

        if (this._updateGuard) {
            clearTimeout(this._updateGuard);
        }

        this._clearTracker();
    }
}

export { IdleTimeoutManager };
