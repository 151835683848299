import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';
import { ACTIONS_ENUM, SUBJECTS_ENUM } from '@/commons/accessControl';
const EngageHub = () =>
    import(
        /* webpackChunkName: "Engagehub" */ '@/modules/engageHub/EngageHub.vue'
    );
const EngageHubLandingPage = () =>
    import(
        /* webpackChunkName: "Engagehub" */ '@/modules/engageHub/pages/EngageHubLandingPage.vue'
    );
const AnnouncementsListingPage = () =>
    import(
        /* webpackChunkName: "Engagehub" */ '@/modules/engageHub/pages/AnnouncementsListingPage.vue'
    );
const EditPublishedAnnouncements = () =>
    import(
        /* webpackChunkName: "Engagehub" */ '@/modules/engageHub/pages/EditPublishedAnnouncements.vue'
    );
const UpdatePublishedAnnouncement = () =>
    import(
        /* webpackChunkName: "Engagehub" */ '@/modules/engageHub/pages/UpdatePublishedAnnouncement.vue'
    );
const AnnouncementCreateEditPage = () =>
    import(
        /* webpackChunkName: "Engagehub" */ '@/modules/engageHub/pages/AnnouncementCreateEditPage.vue'
    );
const AnnouncementDetailPage = () =>
    import(
        /* webpackChunkName: "Engagehub" */ '@/modules/engageHub/pages/AnnouncementDetailPage.vue'
    );
const routes: RouteConfig[] = [
    {
        path: '/engage-hub',
        component: EngageHub,
        children: [
            {
                path: '',
                name: 'EngageHubLandingPage',
                component: EngageHubLandingPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.ANNOUNCEMENTS,
                        },
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.SURVEYS,
                        },
                    ],
                },
            },
            {
                path: 'announcements',
                name: 'AnnouncementsListingPage',
                component: AnnouncementsListingPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.ANNOUNCEMENTS,
                        },
                    ],
                },
            },
            {
                path: 'announcements/create',
                name: 'AnnouncementCreateEditPage',
                component: AnnouncementCreateEditPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.ANNOUNCEMENTS,
                        },
                    ],
                },
            },
            {
                path: 'announcements/edits/:id',
                name: 'EditPublishedAnnouncements',
                component: EditPublishedAnnouncements,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.ANNOUNCEMENTS,
                        },
                    ],
                },
            },
            {
                path: 'announcements/update/:id',
                name: 'UpdatePublishedAnnouncement',
                component: UpdatePublishedAnnouncement,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.ANNOUNCEMENTS,
                        },
                    ],
                },
            },
            {
                path: 'announcements/edit/:id',
                name: 'AnnouncementCreateEditPage',
                component: AnnouncementCreateEditPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.ANNOUNCEMENTS,
                        },
                    ],
                },
            },
            {
                path: 'announcements/detail/:id',
                name: 'AnnouncementDetailPage',
                component: AnnouncementDetailPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.ANNOUNCEMENTS,
                        },
                    ],
                },
            },
        ],
    },
];
export default routes;
