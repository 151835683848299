import { MutationTree } from 'vuex';
import { EmployeeMasterState } from './employeeMaster.state';
import { parseDate } from '@/commons/Utils';

export const UPDATE_EMPLOYEE_DATA = 'updateEmployeeData';
export const INIT_EMPLOYEE_DATA = 'initEmployeeData';
export const UPDATE_EMPLOYEE_FORM_ERRORS = 'updateEmployeeformErrors';
const mutations: MutationTree<EmployeeMasterState> = {
    [INIT_EMPLOYEE_DATA](state, data) {
        state.employeeData = {
            // personal:{
            title: data.attributes.title,
            employeeFirstName: data.attributes.employeeFirstName, //M
            employeeMiddleName: data.attributes.employeeMiddleName,
            employeeLastName: data.attributes.employeeLastName, //M
            dob: parseDate(data.attributes.dob),
            username: data.attributes.username,
            employeeId: data.externalEmpCode, //employee code is the id for end user
            employeeBusinessEmail: data.email, //M
            // },
            // job:{
            employeeGroup: data.attributes.employeeGroup,
            employeeContractType: data.attributes.employeeContractType, //M
            doh: parseDate(data.attributes.doh),
            jobTitle: data.attributes.jobTitle, //M
            jobTitleId: data.attributes.jobTitleId,
            jobGrade: data.attributes.jobGrade,
            accountStatus: data.state, //M
            // },
            // company:{
            companyName: data.attributes.companyName, //M
            businessUnit: data.attributes.businessUnit,
            businessUnitId: data.attributes.businessUnitId,
            department: data.attributes.department,
            departmentId: data.attributes.departmentId,
            costCenter: data.attributes.costCenter,
            teamName: data.attributes.teamName,
            teamId: data.attributes.teamId,
            country: data.attributes.country,

            // },
            // manager:{
            managerName: data.attributes.managerName, //M
            managerEmail: data.attributes.managerEmail, //M
            // }
        };
        state.employeeFormErrors = {};
        state.employeeFormTouched = false;
    },
    [UPDATE_EMPLOYEE_DATA](state, data) {
        state.employeeData = { ...state.employeeData, ...data };
        state.employeeFormErrors = {};
        state.employeeFormTouched = true;
    },
    [UPDATE_EMPLOYEE_FORM_ERRORS](state, data) {
        state.employeeFormErrors = data;
    },
};
export default mutations;
