import dayjs from 'dayjs';
import { BUSINESS_COMPONENTS } from '@/commons/constants';
export interface LooseObject {
    [key: string]: any;
}
export function convertEpochToDateObj(epochTime: any) {
    return new Date(epochTime * 1000);
}

export function convertDateToEpoch(date: any) {
    return date ? Math.floor(date.getTime() / 1000) : date;
}
export function dateFormatter(date: any, options?: any) {
    const { formatString = 'DD MMM YYYY' } = options || {};
    let d: any = date;
    if (typeof d == 'number') {
        //expects seconds
        d = convertEpochToDateObj(date);
    } else if (typeof d == 'string') {
        d = new Date(d);
    }
    d = dayjs(d);
    return d.isValid() ? d.format(formatString) : null;
}
export function parseDate(date: any, options?: any) {
    const { expectedFormat = 'DD/MM/YYYY' } = options || {};
    const d = dayjs(date, expectedFormat);
    return d.isValid() ? d.toDate() : null;
}

export function getFormattedAmount(amount: any, config: any) {
    const value: any = Number(amount / config.denominationFactor)
        .toLocaleString()
        .split('.');
    value[1] = (value[1] || '').padEnd(config.fixedValue, '0');
    return value.join('.');
}

export function isValidPath(sidebarItems: any[], path: string | undefined) {
    if (path === '/session' || path === '/user-profile') {
        return true;
    }

    if (!path || path === '/') {
        return false;
    }

    return (
        sidebarItems.findIndex((sidebarItem: any) => {
            const itemPath: string = sidebarItem.path;
            return path.indexOf(itemPath) === 0;
        }) !== -1
    );
}

export function debounce(fn: any, delay: any) {
    let timerId: any = null;

    return function(...args: any) {
        clearTimeout(timerId);
        timerId = setTimeout(function() {
            fn(...args);
        }, delay);
    };
}

export function throttle(fn: any, delay: any) {
    let timerId: any = null;

    return function() {
        if (!timerId) {
            timerId = setTimeout(function() {
                fn();
                timerId = null;
            }, delay);
        }
    };
}

export function toCamelCase(str: string) {
    return str
        .split(' ')
        .map(function(word, index) {
            // If it is the first word make sure to lowercase all the chars.
            if (index === 0) {
                return word.toLowerCase();
            }
            // If it is not the first word only upper case the first char and lowercase the rest.
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
}

export function getEnv() {
    let env = 'PROD';
    const {
        location: { hostname },
    } = window;
    if (hostname.indexOf('-pp') !== -1) {
        env = 'PREPROD';
    } else if (
        hostname.indexOf('-stage') !== -1 ||
        hostname.indexOf('localhost') !== -1
    ) {
        env = 'STAGE';
    }
    return env;
}

function toBase64(blob: any) {
    return new Promise((resolve: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
            resolve(reader.result);
        };
    });
}

export async function downloadAllFiles(files: any[]) {
    if (files.length) {
        const $body = document.getElementsByTagName('body')[0];
        const file: any = files.pop();
        const fileData = await fetch(file.url);
        const blobData = await fileData.blob();
        const base64Data: any = await toBase64(blobData);
        const $el = document.createElement('a');
        $el.download = file.name || 'ticket';
        const data = base64Data.split(',');
        $el.href = `${data[0]},${encodeURIComponent(data[1])}`;
        $body.appendChild($el);
        $el.click();
        $el.remove();
        downloadAllFiles(files);
    }
}
export function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;//eslint-disable-line
    return re.test(String(email).toLowerCase());
}

export function serializeObject(paramsObj: any) {
    return Object.keys(paramsObj)
        .map(function(key) {
            return key + '=' + encodeURIComponent(paramsObj[key]);
        })
        .join('&');
}

export function loadScript(src: string) {
    return new Promise(function(resolve, reject) {
        const s = document.createElement('script');
        let r = false;
        s.type = 'text/javascript';
        s.src = src;
        s.async = true;
        s.onerror = function(err) {
            reject({ error: err });
        };
        s.onload = function() {
            // console.log(this.readyState); // uncomment this line to see which ready states are called.
            if (!r) {
                r = true;
                resolve({});
            }
        };
        const t: any = document.getElementsByTagName('script')[0];
        t.parentElement.insertBefore(s, t);
    });
}
export function _get(object: any, path: any, defaultValue: any) {
    //copied from lodash _get
    let index = 0;
    path = String(path).split('.');
    const length = path.length;
    while (object != null && index < length) {
        object = object[String(path[index++])];
    }
    return object || defaultValue;
}
function loadZetaBundle() {
    const w: any = window;
    if (w.zeta && w.zeta.vueWebComponentWrapper) {
        return new Promise(resolve => resolve({}));
    }
    return loadScript(
        'https://banking-assets.s3.ap-south-1.amazonaws.com/lib/1.5.5/zeta.min.js'
    );
}
export async function loadWebComponent({ _key }: { _key: string }) {
    const { id, src } = _get(BUSINESS_COMPONENTS, _key, {});
    if (document.createElement(id).constructor !== HTMLElement) {
        //to check if the web component already registered
        return new Promise(resolve => resolve({}));
    }
    return loadZetaBundle().then(() => loadScript(src));
}
