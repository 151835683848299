export const causelList = {
  totalCount: 10,
  entities: [
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 1,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 2,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 3,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 4,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 5,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 6,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 1,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 2,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 3,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 4,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 5,
    },
    {
      title: "IKEA discount voucher",
      discount: "5%",
      image: require("@/assets/voucher/image.png"),
      brandImg: require("@/assets/voucher/brand.svg"),
      id: 6,
    },
  ],
};
export const pastOrderList = {
  totalCount: 28,
  entities: [
    {
      id: 1,
      timestamp: 1601277045739,
      name: "Argos Voucher",
      amount: {
        amount: 100,
        currency: "GBP",
      },
      count: 100,
      totalAmount: {
        amount: 10000,
        currency: "GBP",
      },
      status: "APPROVED",
    },
    {
      id: 2,
      timestamp: 1601277045739,
      name: "Argos Voucher",
      amount: {
        amount: 100,
        currency: "GBP",
      },
      count: 100,
      totalAmount: {
        amount: 10000,
        currency: "GBP",
      },
      status: "REJECTED",
    },
    {
      id: 1,
      timestamp: 1601277045739,
      name: "Argos Voucher",
      amount: {
        amount: 100,
        currency: "GBP",
      },
      count: 100,
      totalAmount: {
        amount: 10000,
        currency: "GBP",
      },
      status: "APPROVED",
    },
    {
      id: 1,
      timestamp: 1601277045739,
      name: "Argos Voucher",
      amount: {
        amount: 100,
        currency: "GBP",
      },
      count: 100,
      totalAmount: {
        amount: 10000,
        currency: "GBP",
      },
      status: "APPROVED",
    },
    {
      id: 1,
      timestamp: 1601277045739,
      name: "Argos Voucher",
      amount: {
        amount: 100,
        currency: "GBP",
      },
      count: 100,
      totalAmount: {
        amount: 10000,
        currency: "GBP",
      },
      status: "APPROVED",
    },
    {
      id: 1,
      timestamp: 1601277045739,
      name: "Argos Voucher",
      amount: {
        amount: 100,
        currency: "GBP",
      },
      count: 100,
      totalAmount: {
        amount: 10000,
        currency: "GBP",
      },
      status: "APPROVED",
    },
    {
      id: 1,
      timestamp: 1601277045739,
      name: "Argos Voucher",
      amount: {
        amount: 100,
        currency: "GBP",
      },
      count: 100,
      totalAmount: {
        amount: 10000,
        currency: "GBP",
      },
      status: "APPROVED",
    },
    {
      id: 1,
      timestamp: 1601277045739,
      name: "Argos Voucher",
      amount: {
        amount: 100,
        currency: "GBP",
      },
      count: 100,
      totalAmount: {
        amount: 10000,
        currency: "GBP",
      },
      status: "APPROVED",
    },
  ],
};
