import Vue, { PluginFunction, PluginObject } from 'vue';
import Buefy from 'buefy';
import dayjs from 'dayjs';

import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';
import { ACTIONS_TYPE, SUBJECTS_TYPE } from '@/commons/accessControl.ts';
const ability = new Ability<[ACTIONS_TYPE, SUBJECTS_TYPE]>();

import './commons/class-component-hooks';
import CurrencyConfig from '@/commons/currencyConfig';
import {
    getFormattedAmount,
    convertEpochToDateObj,
    dateFormatter,
} from '@/commons/Utils';

import './scss/main.scss';

type PluginList = [PluginObject<any> | PluginFunction<any>, any];

const plugins: PluginList[] = [
    [Buefy, null],
    [abilitiesPlugin, ability],
];

const installGlobalPlugins = () => {
    plugins.forEach(item => {
        const [plugin, options] = item;
        if (options) {
            Vue.use(plugin, options);
        } else {
            Vue.use(plugin);
        }
    });
};

const installGlobalFilters = () => {
    Vue.filter('dateFormatter', dateFormatter);
    Vue.filter('dateTimeFormatter', (date: any) =>
        dayjs(
            typeof date == 'number' ? convertEpochToDateObj(date) : date
        ).format('DD MMM YYYY hh:mm  A')
    );

    Vue.filter('amountFormatter', (money: any) => {
        if (!money || typeof money !== 'object') return '';
        const config: any = CurrencyConfig[money.currency || 'INR'];

        if (money.amount < 0) {
            return `-${config.symbol}${getFormattedAmount(
                Math.abs(money.amount),
                config
            )}`;
        }
        return `${config.symbol}${getFormattedAmount(money.amount, config)}`;
    });
    Vue.filter('vcFormatter', (amount: any) => {
        const value: any = Number(amount / 1).toLocaleString();

        return value;
        //     .split('.');
        //value[1] = (value[1] || '').padEnd(2, '0');
        // let c = value.join('.');
        //  return c;
    });

    Vue.filter('accountFormatter', (accountNumber: any) => {
        return accountNumber.slice(-4).padStart(6, 'x');
    });

    Vue.filter('genderFormatter', (genderCode: any) => {
        if (genderCode) {
            if (genderCode === 'M') {
                return 'Male';
            } else if (genderCode === 'F') {
                return 'Female';
            } else {
                return genderCode;
            }
        } else {
            return genderCode;
        }
    });
};

export default function init() {
    installGlobalPlugins();
    installGlobalFilters();
}
