// Set the name of the hidden property and the change event for visibility

//var videoElement = document.getElementById("videoElement");

// If the page is hidden, pause the video;
// if the page is shown, play the video
// function handleVisibilityChange() {
//   if (document[hidden]) {
//     videoElement.pause();
//   } else {
//     videoElement.play();
//   }
// }

// Warn if the browser doesn't support addEventListener or the Page Visibility API

const onVisibilityChange = (cb: any) => {
    let hidden: any, visibilityChange: any;
    const document: any = window.document;
    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }
    if (
        typeof document.addEventListener === 'undefined' ||
        hidden === undefined
    ) {
        console.log(
            'This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.'
        );
    } else {
        // Handle page visibility change
        document.addEventListener(
            visibilityChange,
            () => {
                cb(document[hidden]);
            },
            false
        );
    }
};
export default onVisibilityChange;
