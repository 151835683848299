
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class Modal extends Vue {
    @Prop({ default: () => 'info' }) type!: string;
    @Prop({ default: () => '' }) headerText!: string;
    @Prop() subHeaderText!: string;
    @Prop({ default: () => false }) active!: boolean;
    @Prop({ default: () => false }) hasFooter!: boolean;
    @Prop({ default: () => false }) canCancel!: boolean;
    @Prop({ default: () => ['5', '5'] }) cardBodyPadding: any;
    @Prop({ default: () => true }) canClose: any;

    private isActive = false;

    @Watch('active')
    private onChange(newVal: boolean) {
        this.isActive = newVal;
    }

    private get hasHeader() {
        return this.headerText || this.subHeaderText ? true : false;
    }

    private created() {
        this.isActive = this.active;
    }

    private close() {
        this.$emit('close');
    }
}
