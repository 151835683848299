export const activeBenefits = {
    totalCount: 6,
    benefits: [{
        title: 'Gym MemberShip',
        noOfBeneficiaries: 123,
        image: require('@/assets/benefits_sample.svg'),
        id: 1
    },
    {
        title: 'Tuskar Card',
        noOfBeneficiaries: 12,
        image: require('@/assets/benefits_sample.svg'),
        id: 2
    },{
        title: 'Gym MemberShip',
        noOfBeneficiaries: 123,
        image: require('@/assets/benefits_sample.svg'),
        id: 3
    },
    {
        title: 'Tuskar Card',
        noOfBeneficiaries: 12,
        image: require('@/assets/benefits_sample.svg'),
        id: 4
    },{
        title: 'Gym MemberShip',
        noOfBeneficiaries: 123,
        image: require('@/assets/benefits_sample.svg'),
        id: 5
    },
    {
        title: 'Tuskar Card',
        noOfBeneficiaries: 12,
        image: require('@/assets/benefits_sample.svg'),
        id: 6
    }]
};
export const setupBenefits = {
    totalCount: 1,
    benefits: [{
        title: 'Cycle to work',
        image: require('@/assets/benefits_sample.svg'),
        id: 7
    },]
};
