import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { RootState } from '@/store/types';
import actions from './instantRewards.actions';
import getters from './instantRewards.getters';
import mutations from './instantRewards.mutations';
import state from './instantRewards.state';

import { InstantRewardsState } from './instantRewards.state';

const module: Module<InstantRewardsState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export const INSTANT_REWARDS_STORE = 'instantRewards';

export const InstantRewardsModule = namespace(INSTANT_REWARDS_STORE);

export default module;
