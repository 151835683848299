import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import setup from './setup';
import './scss/main.scss';
import { getURLParameter } from '@zeta/utils';
import { getTokenFromCode } from '@/utils/auth';
Vue.config.productionTip = true;
Vue.config.devtools = true;
// Bootstrap app after user role/details are fetched
setup();

async function initApp(vm: any) {
    vm.$mount('#app');
}

const code = getURLParameter('code');
if (code) {
    // not putting this in context of vue, as this is once a while thing to touch
    getTokenFromCode(code)
        .then(() => {
            const hash = window.location.hash;
            window.history.replaceState(
                null,
                'Spotlight front office',
                window.location.pathname
            );
            window.location.hash = hash;
            const instance = new Vue({
                router,
                store,
                render: h => h(App),
            });
            initApp(instance);
        })
        .catch(() => {
            console.log('App level error');
        });
} else {
    const instance = new Vue({
        router,
        store,
        render: h => h(App),
    });
    initApp(instance);
}
