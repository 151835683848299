import { AxiosInstance } from 'axios';
//import { getEnv } from '@/commons/Utils';

import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';

class RewardsService {
    private axios: AxiosInstance;

    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
    }
    private get servicePath() {
        return `/spotlight-rewards/1.0`;
    }

    public getCurrencyList(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/currencies`,
            { params }
        );
    }
    public getBudgetHolderList(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/rewardOfferings/${pathParams.rewardOfferingId}/budgets/summary`,
            { params }
        );
    }
    public getBudgetSummary(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/rewardOfferings/${pathParams.rewardOfferingId}/summary`,
            { params }
        );
    }
    public getAccountBalances(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/balance`,
            { params }
        );
    }
    public fetchUserAccountHolderDetails(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenant/${pathParams.tenantId}/accountHolder`,
            { params }
        );
    }
}

const config = {
    baseURL: __APP__.REWARDS.BASE_URL,
    resolveAuthToken: () => {
        return localStorage.getItem('@zeta::authToken') || '';
    },
};
export default new RewardsService(config);
