import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';
import { ACTIONS_ENUM, SUBJECTS_ENUM } from '@/commons/accessControl';

const InstantRewardsPage = () =>
    import(
        /* webpackChunkName: "InstantRewardsPage" */ '@/modules/instantRewards/InstantRewards.vue'
    );

const InstantRewardsLandingPage = () =>
    import(
        /* webpackChunkName: "InstantRewardsLandingPage" */ '@/modules/instantRewards/pages/InstantRewardsLandingPage.vue'
    );
const AllCategoriesPage = () =>
    import(
        /* webpackChunkName: "AllCategoriesPage" */ '@/modules/instantRewards/pages/AllCategories.vue'
    );
const CategoryPage = () =>
    import(
        /* webpackChunkName: "CategoryPage" */ '@/modules/instantRewards/pages/CategoryPage.vue'
    );

const BasketPage = () =>
    import(
        /* webpackChunkName: "BasketPage" */ '@/modules/instantRewards/pages/BasketPage.vue'
    );

const SubcategoryPage = () =>
    import(
        /* webpackChunkName: "CategoryPage" */ '@/modules/instantRewards/pages/CategoryPage.vue'
    );

const InstantRewardsVoucherDetails = () =>
    import(
        /* webpackChunkName: "InstantRewardsVoucherDetails" */ '@/modules/instantRewards/pages/InstantRewardsVoucherDetails.vue'
    );

const InstantRewardsOrderSummaryPage = () =>
    import(
        /* webpackChunkName: "OrderSummary" */ '@/modules/instantRewards/pages/OrderSummary.vue'
    );

const InstantRewardsOrderHistory = () =>
    import(
        /* webpackChunkName: "InstantRewardsOrderHistory" */ '@/modules/instantRewards/pages/OrderHistory.vue'
    );

const InstantRewardsOrderDetails = () =>
    import(
        /* webpackChunkName: "InstantRewardsOrderHistory" */ '@/modules/instantRewards/pages/OrderDetails.vue'
    );
const InstantRewardsDistributionHome = () =>
    import(
        /* webpackChunkName: "InstantRewardsOrderHistory" */ '@/modules/instantRewards/pages/DistributeVoucher.vue'
    );
const InstantRewardsDistributionErrors = () =>
    import(
        /* webpackChunkName: "InstantRewardsOrderHistory" */ '@/modules/instantRewards/pages/DistributeVoucherErrors.vue'
    );
const routes: RouteConfig[] = [
    {
        path: MODULES.INSTANT_REWARDS.path,
        component: InstantRewardsPage,
        children: [
            {
                path: '',
                name: pages.InstantRewardsLandingPage.name,
                component: InstantRewardsLandingPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: 'categories',
                name: pages.AllCategoriesPage.name,
                component: AllCategoriesPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: 'categories/:id',
                name: pages.CategoryPage.name,
                component: CategoryPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: 'categories/:categoryId/subcategory/:id',
                name: pages.SubcategoryPage.name,
                component: SubcategoryPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: 'basket',
                name: pages.InstantRewardsBasketPage.name,
                component: BasketPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: 'categories/:id',
                name: pages.CategoryPage.name,
                component: CategoryPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: 'voucher-details/:id',
                name: pages.VoucherDetailsPage.name,
                component: InstantRewardsVoucherDetails,
                props: true,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: 'order-summary',
                name: pages.InstantRewardsOrderSummaryPage.name,
                component: InstantRewardsOrderSummaryPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: 'order-history',
                name: pages.IrOrderHistoryPage.name,
                component: InstantRewardsOrderHistory,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: 'order-details/:id',
                name: pages.IrOrderDetailsPage.name,
                component: InstantRewardsOrderDetails,
                props: true,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: ':orderId/distribution',
                name: pages.IrOrderDetailsPage.name,
                component: InstantRewardsDistributionHome,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
            {
                path: ':orderId/distribution/errors/:debugId',
                name: pages.IrOrderDetailsPage.name,
                component: InstantRewardsDistributionErrors,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.INSTANT_REWARDS,
                        },
                    ],
                },
            },
        ],
    },
];

export default routes;
