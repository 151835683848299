import { ModuleTree } from 'vuex';
import { RootState } from './types';
import benefitStore, { BENEFITS_STORE } from '../modules/benefits/store/index';
import voucherStore, { VOUCHER_STORE } from '../modules/voucher/store/index';
import recognitionStore, {
    RECOGNITION_STORE,
} from '../modules/recognition/store/index';
import employeeMasterStore, {
    EMPLOYEE_MASTER_STORE,
} from '../modules/employeeMaster/store/index';

import instantRewardsStore, {
    INSTANT_REWARDS_STORE,
} from '../modules/instantRewards/store/index';

const modules: ModuleTree<RootState> = {
    [BENEFITS_STORE]: benefitStore,
    [VOUCHER_STORE]: voucherStore,
    [RECOGNITION_STORE]: recognitionStore,
    [EMPLOYEE_MASTER_STORE]: employeeMasterStore,
    [INSTANT_REWARDS_STORE]: instantRewardsStore,
};

export default modules;
