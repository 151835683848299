import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { RootState } from './types';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import modules from './modules';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    state,
    getters,
    modules,
    mutations,
    actions,
};

export default new Vuex.Store<RootState>(store);
