
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

import Sidebar from '../Sidebar.vue';

import { EventBus, Events } from '@/commons/eventBus';

@Component({ components: { Sidebar } })
export default class Layout extends Vue {
    @Prop({ default: false }) pageError!: boolean;
    @State('noSidebar')
    private noSidebar!: boolean;

    private count = 0;

    private get isLoading() {
        if (this.count > 0) return true;
        return false;
    }

    private showLoader() {
        this.count++;
    }
    private hideLoader() {
        if (this.count) {
            this.count--;
        }
    }

    private created() {
        EventBus.$on(Events.showLoader, this.showLoader);
        EventBus.$on(Events.hideLoader, this.hideLoader);
    }
    private beforeDestroy() {
        EventBus.$off(Events.showLoader, this.showLoader);
        EventBus.$off(Events.hideLoader, this.hideLoader);
    }
}
