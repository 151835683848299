import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { EmployeeMasterState } from './employeeMaster.state';
import {
    FETCH_EMPLOYEES,
    FETCH_EMPLOYEE,
    FETCH_ORDERS,
    UPDATE_EMPLOYEE,
    CREATE_EMPLOYEE,
    CREATE_BULK_ORDER,
    FETCH_EMPLOYEES_BULK_TEMP_ORDER,
    FETCH_ORDER_DETAILS,
    DOWNLOAD_BULK_ORDER_ERROR_FILE,
    CANCEL_ORDER,
} from './employeeMaster.actions.types';

import CorpCoreService from '@/services/corpcore';
import { EventBus, Events } from '@/commons/eventBus';
import { INIT_EMPLOYEE_DATA } from './employeeMaster.mutations';
// import { delete } from 'vue/types/umd';
// import { delete } from 'vue/types/umd';

const actions: ActionTree<EmployeeMasterState, RootState> = {
    async [FETCH_EMPLOYEES]({ rootState }, params) {
        // console.log("rootState ==>", rootState);
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            };
            CorpCoreService.fetchEmployees(pathParams, params).then(
                resp => {
                    //   EventBus.$emit(Events.hideLoader);
                    resolve({
                        ...resp.data,
                        params: resp.config.params,
                    });
                },
                err => {
                    //   EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        params: err.config.params,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_ORDERS]({ rootState }, params) {
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            };

            CorpCoreService.fetchOrders(pathParams, params).then(
                resp => {
                    //   EventBus.$emit(Events.hideLoader);
                    resolve(resp.data);
                    // resolve(orderList);
                },
                err => {
                    //   EventBus.$emit(Events.hideLoader);
                    // resolve(orderList);
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_ORDER_DETAILS]({ rootState }, params) {
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                orderId: params.orderId,
                tenantId: rootState.tenantId,
            };
            delete params.orderId;
            CorpCoreService.fetchOrderDetails(pathParams, params).then(
                resp => {
                    //   EventBus.$emit(Events.hideLoader);
                    resolve(resp.data);
                    // resolve(orderList);
                },
                err => {
                    //   EventBus.$emit(Events.hideLoader);
                    // resolve(orderList);
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_EMPLOYEE]({ rootState, commit }, params) {
        // console.log("rootState ==>", rootState);
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                empId: params.empId,
                tenantId: rootState.tenantId,
            };
            delete params.empId;
            CorpCoreService.fetchEmployee(pathParams, params).then(
                resp => {
                    commit(INIT_EMPLOYEE_DATA, resp.data);
                    //   EventBus.$emit(Events.hideLoader);
                    resolve({ data: resp.data });
                },
                err => {
                    //   EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [CANCEL_ORDER]({ rootState, commit }, params) {
        // console.log("rootState ==>", rootState);
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                orderId: params.orderId,
                tenantId: rootState.tenantId,
            };
            CorpCoreService.cancelOrder(pathParams, params).then(
                resp => {
                    resolve({ data: resp.data });
                },
                err => {
                    resolve({
                        isError: true,
                        // ...err.toJSON(),//temporary fix : no response received
                    });
                }
            );
        });
    },
    async [UPDATE_EMPLOYEE]({ rootState, commit }, params) {
        // console.log("rootState ==>", rootState);
        // EventBus.$emit(Events.showLoader);
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                empId: params.empId,
                tenantId: rootState.tenantId,
            };
            delete params.empId;
            CorpCoreService.updateEmployee(pathParams, params).then(
                resp => {
                    // commit(UPDATE_EMPLOYEE_DATA,resp.data)
                    // EventBus.$emit(Events.hideLoader);
                    resolve(resp.data);
                },
                err => {
                    // EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        params: err.config.params,
                        ...err.response,
                        // ...err.toJSON(), temporary fix : no response received
                    });
                }
            );
        });
    },
    async [CREATE_EMPLOYEE]({ rootState, commit }, params) {
        // console.log("rootState ==>", rootState);
        EventBus.$emit(Events.showLoader);
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            };
            CorpCoreService.createEmployee(pathParams, params).then(
                resp => {
                    // commit(UPDATE_EMPLOYEE_DATA,resp.data)
                    EventBus.$emit(Events.hideLoader);
                    resolve(resp.data);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        params: err.config.params,
                        ...err.response,
                    });
                }
            );
        });
    },

    async [CREATE_BULK_ORDER]({ rootState, commit }, params) {
        // console.log("rootState ==>", rootState);
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
            };
            CorpCoreService.createBulkOrder(pathParams, params).then(
                resp => {
                    // commit(UPDATE_EMPLOYEE_DATA, resp.data);
                    resolve(resp.data);
                },
                err => {
                    //   EventBus.$emit(Events.hideLoader);
                    // console.log('Error', err);
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_EMPLOYEES_BULK_TEMP_ORDER]({ rootState, commit }, params) {
        // console.log("rootState ==>", rootState);
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                // empId: params.empId,
                orderId: params.orderId,
                tenantId: rootState.tenantId,
            };
            delete params.orderId;
            CorpCoreService.fetchEmployeesBulkTempOrder(
                pathParams,
                params
            ).then(
                resp => {
                    // commit(INIT_EMPLOYEE_DATA, resp.data);
                    //   EventBus.$emit(Events.hideLoader);
                    resolve(resp.data);
                },
                err => {
                    //   EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [DOWNLOAD_BULK_ORDER_ERROR_FILE]({ rootState, commit }, params) {
        return new Promise(resolve => {
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                orderId: params.orderId,
                tenantId: rootState.tenantId,
            };
            CorpCoreService.downloadBulkOrderErrorFile(pathParams).then(
                resp => {
                    resolve(resp.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
};

export default actions;
