import {
    AUTH_TOKEN_KEY,
    VALID_TILL_KEY,
    REFRESH_TOKEN_KEY,
    TOKEN_TYPE_KEY,
    AuthStore,
    CipherSSOResponse,
} from '@zeta/authentication';

import { serializeObject } from '@/commons/Utils';
export function storeToken(cipherSSOResponse: CipherSSOResponse) {
    const authStore: any = AuthStore;

    authStore.setItem(AUTH_TOKEN_KEY, cipherSSOResponse.access_token);
    authStore.setItem(REFRESH_TOKEN_KEY, cipherSSOResponse.refresh_token);
    authStore.setItem(TOKEN_TYPE_KEY, cipherSSOResponse.token_type);
    authStore.setItem(
        VALID_TILL_KEY,
        Date.now() + cipherSSOResponse.expires_in
    );
}
export function isLoggedIn() {
    return (
        window.localStorage.getItem('@zeta::authToken') !== null &&
        window.localStorage.getItem('@zeta::authToken') !== 'undefined'
    );
}

export function refreshToken() {
    const authStore: any = AuthStore;
    const loginParams: any = __APP__.CIPHER_PARAMS.loginParams;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    //myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    const urlencoded = new URLSearchParams();
    urlencoded.append('client_id', loginParams.client_id);
    // urlencoded.append('client_secret', __APP__.CIPHER_PARAMS.secret);
    urlencoded.append('grant_type', REFRESH_TOKEN_KEY);
    urlencoded.append(
        REFRESH_TOKEN_KEY,
        encodeURIComponent(authStore.getItem(REFRESH_TOKEN_KEY))
    );

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(Object.fromEntries(urlencoded)),
        // body: urlencoded
    };

    return new Promise((resolve, reject) => {
        //  fetch(`${__APP__.CIPHER_PARAMS.baseUrl}/token`, requestOptions)
        const tenantId = window.localStorage.getItem('sessionTenantInfo');
        fetch(
            `${__APP__.SPOTLIGHT_CORE.BASE_URL}${__APP__.SPOTLIGHT_CORE.BASE_PATH}/tenants/${tenantId}/token`,
            requestOptions
        )
            .then(response => response.json())
            .then(res => {
                console.log(res);
                if (res && res.access_token) {
                    storeToken(res);
                    resolve(res);
                } else {
                    reject(
                        new Error('#LOGOUT - Invalid access token from refresh')
                    );
                }
            })
            .catch(e => {
                console.error(e.message);
                reject(new Error('#LOGOUT - Refresh token api failed'));
            });
    });
}

export function getTokenFromCode(code: string) {
    const CIPHER_PARAMS = __APP__.CIPHER_PARAMS;
    const payload = new URLSearchParams();
    payload.append('redirect_uri', CIPHER_PARAMS.loginParams.redirect_uri);
    payload.append('code', encodeURIComponent(code));
    payload.append('client_id', CIPHER_PARAMS.loginParams.client_id);
    //   payload.append('client_secret', CIPHER_PARAMS.secret);
    payload.append('grant_type', 'authorization_code');
    //return fetch('' + CIPHER_PARAMS.baseUrl + '/access_token', {
    const tenantId = window.localStorage.getItem('sessionTenantInfo');
    return fetch(
        `${__APP__.SPOTLIGHT_CORE.BASE_URL}${__APP__.SPOTLIGHT_CORE.BASE_PATH}/tenants/${tenantId}/access_token`,
        {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json',
            },
            // body: payload,
            body: JSON.stringify(Object.fromEntries(payload)),
        }
    )
        .then(function(res) {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error('Error while fetching accessToken');
            }
        })
        .then(storeToken);
}

export function redirectToCipherSSO({
    overRideParams,
}: {
    overRideParams: any;
}) {
    const url =
        __APP__.CIPHER_PARAMS.baseUrl +
        '/authorize?' +
        serializeObject({
            ...__APP__.CIPHER_PARAMS.loginParams,
            ...overRideParams,
        });
    window.location.href = url;
}
