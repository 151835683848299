const CurrenyConfig: any = Object.freeze({
    INR: {
        symbol: '₹',
        denominationFactor: 100,
        fixedValue: 2,
        validator: (val: any) => {
            return /^\d+\.?(?:\d{1,2})?$/.test(val);
        },
        icon: 'currency-inr',
    },
    USD: {
        symbol: '$',
        denominationFactor: 100,
        fixedValue: 2,
        validator: (val: any) => {
            return /^\d+\.?(?:\d{1,2})?$/.test(val);
        },
        icon: 'currency-usd',
        isDisabled: true,
    },
    GBP: {
        symbol: '£',
        denominationFactor: 100,
        fixedValue: 2,
        validator: (val: any) => {
            return /^\d+\.?(?:\d{1,2})?$/.test(val);
        },
        icon: 'currency-gbp',
        isDisabled: true,
    },
});
export default CurrenyConfig;

export const virtualCurrencyConfig: any = Object.freeze({
    'PNT': {
        symbol: "⬥",
        denominationFactor: 1,
        validator: (val: any) => {
            return /^\d+$/.test(val);
        },
        icon: '',
        name: 'Point',
        pluralName: 'Points',
        isDisabled: false,
        digitGrouping: 'Thousands',
        code: 'PNT',
        conversionRatio: 10,
        baseCurrency: 'GBP',
        unicode: '&#x2B25;'
    },
    'STR': {
        symbol: "⭑",
        denominationFactor: 1,
        validator: (val: any) => {
            return /^\d+$/.test(val);
        },
        icon: '',
        name: 'Star',
        pluralName: 'Stars',
        isDisabled: false,
        digitGrouping: 'Thousands',
        code: 'STR',
        conversionRatio: 100,
        baseCurrency: 'GBP',
        unicode: '&#x2B51;'
    }

})
