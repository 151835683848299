import { MutationTree } from 'vuex';
import { RecognitionState } from './recognition.state';
//import { UPDATE_CREATE_PROGRAM } from './recognition.mutations.types';

export const UPDATE_CREATE_PROGRAM = 'updateCreateProgram';
export const UPDATE_PROGRAM_DETAIL = 'updateProgramDetail';
export const UPDATE_BADGES = 'updateBadges';
export const RESET_PROGRAM_DETAIL = 'resetProgramDetail';
export const UPDATE_PRODUCT_CONFIG = 'updateProductConfig';
export const UPDATE_GROUPS = 'updateGroups';
export const UPDATE_TAB = 'updateTab';
export const UPDATE_PROGRAM_PRESETS = 'updateProgramPresets';
export const UPDATE_AWARD_PRESETS = 'updateAwardPresets';
export const UPDATE_CURRENCY_LIST = 'updateCurrencyList';
export const UPDATE_RECOGNITION_BALANCE = 'updateRecognitionBalance';
export const UPDATE_PROGRAM_AUDIENCE = 'updateProgramAudience';
export const SET_REVOKE_DETAILS = 'setRevokeDetails';
export const CLEAR_PROGRAM_AUDIENCE = 'clearProgramAudience';

const mutations: MutationTree<RecognitionState> = {
    [UPDATE_CREATE_PROGRAM](state, data) {
        state.createProgram = data;
    },
    [UPDATE_PROGRAM_DETAIL](state, data) {
        state.programDetail = data;
    },
    [UPDATE_BADGES](state, data) {
        state.badges = data;
    },
    [RESET_PROGRAM_DETAIL](state) {
        state.badges = [];
        state.programDetail = null;
        state.tab = 0;
    },
    [UPDATE_PRODUCT_CONFIG](state, data) {
        state.productConfig = data;
    },
    [UPDATE_GROUPS](state, data) {
        state.groups = data;
    },
    [UPDATE_TAB](state, data) {
        state.tab = data;
    },
    [UPDATE_PROGRAM_PRESETS](state, data) {
        state.programPresets = data;
    },
    [UPDATE_AWARD_PRESETS](state, data) {
        state.awardPresets = data;
    },
    [UPDATE_CURRENCY_LIST](state, data) {
        state.currencyList = data;
    },
    [UPDATE_RECOGNITION_BALANCE](state, data) {
        state.balance = data.availableCredit * 100;
    },
    [UPDATE_PROGRAM_AUDIENCE](state, data) {
        state.audience = { ...state.audience, ...data };
    },
    [SET_REVOKE_DETAILS](state, data) {
        state.revokeDetails = data;
    },
    [CLEAR_PROGRAM_AUDIENCE](state, data) {
        state.audience = {};
    },
};
export default mutations;
