import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { InstantRewardsState } from './instantRewards.state';
import {
    FETCH_CATEGORY_LIST,
    FETCH_CATEGORY,
    FETCH_SALEITEMS_LIST,
    FETCH_SALEITEM_BY_ID,
    FETCH_SUBCATEGORY_LIST,
    FETCH_INSTANT_REWARDS_BALANCE,
    VALIDATE_BASKET,
    EVALUATE_BASKET,
    FETCH_BILLING_ADDRESS,
    FETCH_ORDER_HISTORY,
    FETCH_MARKETPLACE_PROVIDER,
    FETCH_ORDER_DETAILS_BY_ID,
    DOWNLOAD_DISTRIBUTION_TEMPLATE,
    PLACE_ORDER,
    PLACE_DISTRIBUTION_ORDER,
    FETCH_SALEITEMS_FILTERS,
} from './instantRewards.actions.types';

import MarketplaceService from '@/services/marketplace';
import {
    UPDATE_INSTANT_REWARDS_BALANCE,
    SET_MARKETPLACE_PROVIDER,
} from './instantRewards.mutations.types';
import { EventBus, Events } from '@/commons/eventBus';
import { v4 as uuidv4 } from 'uuid';

// import { delete } from 'vue/types/umd';
// import { delete } from 'vue/types/umd';

const actions: ActionTree<InstantRewardsState, RootState> = {
    async [FETCH_CATEGORY_LIST]({ rootState }, params) {
        // const res = await MarketplaceService.fetchCategories({ params });
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            ...params,
        };

        return new Promise(resolve => {
            MarketplaceService.fetchCategories(pathParams, {
                catalogueTypes: 'INSTANT_REWARDS',
                ...params,
            }).then(
                resp => {
                    // reject()
                    resolve(resp.data.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_SUBCATEGORY_LIST]({ rootState }, params) {
        // const res = await MarketplaceService.fetchCategories({ params });
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            ...params,
        };

        return new Promise(resolve => {
            MarketplaceService.fetchSubCategoriesById(pathParams, {
                catalogueTypes: 'INSTANT_REWARDS',
                ...params,
            }).then(
                resp => {
                    // reject()
                    resolve(resp.data.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_CATEGORY]({ rootState }, params) {
        // const res = await MarketplaceService.fetchCategories({ params });
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            ...params,
        };

        delete params.categoryId;
        return new Promise(resolve => {
            MarketplaceService.fetchCategory(pathParams, {
                catalogueTypes: 'INSTANT_REWARDS',
                ...params,
            }).then(
                resp => {
                    resolve(resp.data.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },

    async [FETCH_SALEITEMS_LIST]({ rootState }, { payload, params }) {
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            ...params,
        };

        return new Promise(resolve => {
            MarketplaceService.fetchSaleItems(pathParams, {
                params,
                ...payload,
            }).then(
                resp => {
                    resolve(resp.data.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_SALEITEMS_FILTERS](
        { rootState },
        { params, queryString } = {}
    ) {
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        const res = await MarketplaceService.fetchSaleItemFilters(pathParams, {
            params,
            queryString,
        });

        return res.data.data;
    },

    async [FETCH_SALEITEM_BY_ID]({ rootState }, id: string) {
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        const res = await MarketplaceService.fetchSaleItemById(pathParams, id);
        return res.data.data;
    },
    async [FETCH_ORDER_HISTORY]({ rootState, state }, params: any) {
        const mId = window.localStorage.getItem('marketplaceId');
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            marketplaceId:
                state?.marketplaceProvider?.defaultMarketPlace?.id ||
                JSON.parse(mId as string),
        };
        const res = await MarketplaceService.fetchOrderHistory(
            pathParams,
            params
        );
        return res.data.data;
    },
    async [DOWNLOAD_DISTRIBUTION_TEMPLATE]({ rootState, state }, params: any) {
        return new Promise((resolve, reject) => {
            const mId = window.localStorage.getItem('marketplaceId');
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
                orderId: params.orderId,
                marketplaceId:
                    state?.marketplaceProvider?.defaultMarketPlace?.id ||
                    JSON.parse(mId as string),
            };
            MarketplaceService.downloadIrDistributionTemplate(pathParams)
                .then(res => {
                    resolve({
                        data: res.data,
                        isError: false,
                    });
                })
                .catch(err => {
                    resolve({
                        isError: true,
                        data: err,
                    });
                });
        });
    },
    async [FETCH_MARKETPLACE_PROVIDER]({ rootState, commit }, params) {
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };
        const res = await MarketplaceService.fetchMarketplaceProvider(
            pathParams,
            rootState.espId
        );
        const { id } = res.data.data.defaultMarketPlace;
        window.localStorage.setItem('marketplaceId', JSON.stringify(id));
        commit(SET_MARKETPLACE_PROVIDER, res.data.data);
        return res.data.data;
    },
    async [FETCH_ORDER_DETAILS_BY_ID]({ rootState, state }, id: string) {
        const mId = window.localStorage.getItem('marketplaceId');
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            marketplaceId:
                state?.marketplaceProvider?.defaultMarketPlace?.id ||
                JSON.parse(mId as string),
        };
        const res = await MarketplaceService.fetchOrderDetailsById(
            pathParams,
            id
        );
        return res.data.data;
    },
    // async [FETCH_SUBCATEGORY_LIST]({ rootState }, id: number) {
    //     const res = await MarketplaceService.fetchSubCategoriesById(id);
    //     return res.data.data;
    // },
    // async [FETCH_SALEITEMS_BY_CATEGORY_ID]({ rootState }, id: number) {
    //     const res = await MarketplaceService.fetchSaleItemsByCategoryId(id);
    //     return res.data.data;
    // },
    async [FETCH_INSTANT_REWARDS_BALANCE](
        { rootState, commit, state },
        cached = false
    ) {
        if (cached && state.instantRewardsBalance) {
            return new Promise(resolve => {
                resolve(state.instantRewardsBalance);
            });
        }
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
        };

        return new Promise(resolve => {
            MarketplaceService.getAccountBalances(pathParams).then(
                resp => {
                    commit(UPDATE_INSTANT_REWARDS_BALANCE, resp.data.data);
                    resolve(resp.data.data);
                },
                err => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [VALIDATE_BASKET]({ rootState, state }, payload) {
        const mId = window.localStorage.getItem('marketplaceId');
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            marketplaceId:
                state?.marketplaceProvider?.defaultMarketPlace?.id ||
                JSON.parse(mId as string),
        };
        payload.requestId = uuidv4();
        // const teamHash = btoa(JSON.stringify(teamDetails));
        const p = { ...payload };
        //   p.basketItems = btoa(JSON.stringify(p.basketItems));
        //    p.updateItems = btoa(JSON.stringify(p.updateItems));
        return new Promise((resolve, reject) => {
            MarketplaceService.validateBasket(pathParams, p)
                .then(resp => {
                    resolve(resp.data.data);
                })
                .catch(err => reject(err));
        });
    },

    async [PLACE_ORDER]({ rootState, state }, params) {
        return new Promise(resolve => {
            const mId = window.localStorage.getItem('marketplaceId');
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
                marketplaceId:
                    state?.marketplaceProvider?.defaultMarketPlace?.id ||
                    JSON.parse(mId as string),
            };
            const payload = {
                ...params,
                marketplaceId: pathParams.marketplaceId,
                idempotencyKey: uuidv4(),
                catalogueType: 'INSTANT_REWARDS',
                paymentDetails: {
                    paymentProvider: 'SPOTLIGHT',
                },
            };

            MarketplaceService.placeOrder(pathParams, payload)
                .then(res => {
                    console.log('##then', res.data);
                    resolve({
                        res: res.data.data,
                    });
                })
                .catch(err => {
                    console.log('##caught');
                    resolve({
                        res: err?.response?.data,
                        isError: true,
                    });
                });
        });
    },
    async [PLACE_DISTRIBUTION_ORDER]({ rootState, state }, params) {
        return new Promise(resolve => {
            const mId = window.localStorage.getItem('marketplaceId');
            const pathParams = {
                espId: rootState.espId,
                corpId: rootState.corpId,
                compId: rootState.compId,
                tenantId: rootState.tenantId,
                marketplaceId:
                    state?.marketplaceProvider?.defaultMarketPlace?.id ||
                    JSON.parse(mId as string),
                orderId: params.orderId,
            };
            delete params.orderId;
            const payload = {
                ...params,
                requestId: uuidv4(),
                jobType: 'BULK_ORDER_RESOURCES_DISTRIBUTION',
            };

            MarketplaceService.placeDistributionOrder(pathParams, payload)
                .then(res => {
                    resolve({
                        res: res.data.data,
                    });
                })
                .catch(err => {
                    resolve({
                        res: err,
                        isError: true,
                    });
                });
        });
    },

    async [EVALUATE_BASKET]({ rootState, state }, payload) {
        const mId = window.localStorage.getItem('marketplaceId');
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            marketplaceId:
                state?.marketplaceProvider?.defaultMarketPlace?.id ||
                JSON.parse(mId as string),
        };
        payload.requestId = uuidv4();
        return new Promise((resolve, reject) => {
            MarketplaceService.evaluateBasket(pathParams, payload)
                .then(resp => {
                    resolve(resp.data.data);
                })
                .catch(err => reject(err));
        });
    },

    async [FETCH_BILLING_ADDRESS]({ rootState, state }) {
        const mId = window.localStorage.getItem('marketplaceId');
        const pathParams = {
            espId: rootState.espId,
            corpId: rootState.corpId,
            compId: rootState.compId,
            tenantId: rootState.tenantId,
            marketplaceId:
                state?.marketplaceProvider?.defaultMarketPlace?.id ||
                JSON.parse(mId as string),
        };
        const res = await MarketplaceService.fetchBillingAddress(pathParams);
        return res.data;
    },
};

export default actions;
