import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';
import { ACTIONS_ENUM, SUBJECTS_ENUM } from '@/commons/accessControl';

const AdminPage = () =>
    import(/* webpackChunkName: "AdminPage" */ '@/modules/admin/Admin.vue');
const AdminLandingPage = () =>
    import(
        /* webpackChunkName: "AdminLandingPage" */ '@/modules/admin/pages/AdminLandingPage.vue'
    );
const CompanyBalancePage = () =>
    import(
        /* webpackChunkName: "CompanyBalancePage" */ '@/modules/admin/pages/CompanyBalancePage.vue'
    );
const CorporateBrandingPage = () =>
    import(
        /* webpackChunkName: "CorporateBrandingPage" */ '@/modules/admin/pages/CorporateBrandingPage.vue'
    );
const CorporateBrandingEditPage = () =>
    import(
        /* webpackChunkName: "CorporateBrandingEditPage" */ '@/modules/admin/pages/CorporateBrandingEditPage.vue'
    );
const AccountTransactionsPage = () =>
    import(
        /* webpackChunkName: "AdminPage" */ '@/modules/admin/pages/AccountTransactionsPage.vue'
    );

const OnboardingPage = () =>
    import(
        /* webpackChunkName: "OnboardingPage" */ '@/modules/admin/pages/OnboardingPage.vue'
    );

const AccessControlListPage = () =>
    import(
        /* webpackChunkName: "AdminPage" */ '@/modules/admin/pages/AccessControl/AccessList.vue'
    );

const AccessControlGrantAccessPage = () =>
    import(
        /* webpackChunkName: "AdminPage" */ '@/modules/admin/pages/AccessControl/GrantAccess.vue'
    );

const routes: RouteConfig[] = [
    {
        path: '/admin-console',
        component: AdminPage,
        children: [
            {
                path: '',
                name: 'AdminLandingPage',
                component: AdminLandingPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.ADMIN_CONSOLE,
                        },
                    ],
                },
            },
            {
                path: 'company-balance',
                name: 'CompanyBalancePage',
                component: CompanyBalancePage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.COMPANY_BALANCE,
                        },
                    ],
                },
            },
            {
                path: 'company-balance/:id',
                name: 'AccountTransactionsPage',
                component: AccountTransactionsPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.COMPANY_BALANCE,
                        },
                    ],
                },
            },
            {
                path: 'corporate-branding',
                name: 'CorporateBrandingPage',
                component: CorporateBrandingPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.THEMES,
                        },
                    ],
                },
            },
            {
                path: 'corporate-branding/edit',
                name: 'CorporateBrandingEditPage',
                component: CorporateBrandingEditPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.THEMES,
                        },
                    ],
                },
            },
            {
                path: 'onboarding',
                name: 'OnboardingPage',
                component: OnboardingPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.THEMES,
                        },
                    ],
                },
            },
            {
                path: 'access-control',
                name: 'AccessControlPage',
                component: AccessControlListPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.READ,
                            subject: SUBJECTS_ENUM.ACCESS_CONTROL,
                        },
                    ],
                },
            },
            {
                path: 'access-control/grant-access',
                name: 'GrantAccessPage',
                component: AccessControlGrantAccessPage,
                meta: {
                    accesses: [
                        {
                            action: ACTIONS_ENUM.ACTION,
                            subject: SUBJECTS_ENUM.ACCESS_CONTROL,
                        },
                    ],
                },
            },
        ],
    },
];

export default routes;
