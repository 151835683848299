export const FETCH_AUDIENCE_LIST = 'fetchAudienceList';
export const FETCH_PROGRAM_LIST = 'fetchProgramList';
export const CREATE_PROGRAM = 'createProgram';
export const FETCH_PROGRAM = 'fetchProgram';
export const FETCH_BADGE = 'fetchBadge';
export const FETCH_BADGE_REPORT = 'fetchBadgeReport';
export const CREATE_BADGE = 'createBadge';
export const FETCH_BADGES = 'fetchBadges';
export const DELETE_BADGE = 'deleteBadge';
export const GET_GROUPS = 'getGroups';
export const GET_GROUP = 'getGroup';
export const GET_GROUP_BY_GROUP_ID_LIST = 'getGroupByGroupIDList';
export const FETCH_PRODUCT_CONFIG = 'fetchProductConfig';
export const FETCH_BADGE_PRESETS = 'fetchBadgePresets';
export const FETCH_PROGRAM_PRESETS = 'fetchProgramPresets';
export const FETCH_CURRENCY_LIST = 'fetchCurrencyList';
export const FETCH_BUDGET_HOLDER_LIST = 'fetchBudgetHolderList';
export const FETCH_BUDGET_SUMMARY = 'FetchBudgetSummary';
export const FETCH_AWARDS_ISSUANCE_LIST = 'fetchAwardsIssuanceList';
export const FETCH_ALLOCATION_ORDER = 'fetchAllocationOrder';
export const FETCH_ALLOCATION_HISTORY = 'fetchAllocationHistory';
export const CREATE_ALLOCATE_BUDGET_ORDER = 'createAllocationOrder';
export const CREATE_ALLOCATE_BUDGET_ORDER_BULK = 'createAllocationOrderBulk';
export const FETCH_RECOGNITION_ACCOUNT_BALANCE =
    'fetchRecognitionAccounBalance';
export const FETCH_AWARD_REPORT = 'fetchAwardReport';
export const FETCH_ALL_PROGRAM_AUDIENCE = 'fetchAllProgramAudience';
export const FETCH_AWARD_DISTRUBUTION = 'fetchAwardDistrubution';
export const FETCH_EMPLOYEES = 'fetchEmployees';

export const FETCH_AWARD_ISSUANCE_SUMMARY = 'fetchAwardIssuanceSummary';
export const FETCH_AWARD_ISSUANCE_DETAILS = 'fetchAwardIssuanceDetails';
