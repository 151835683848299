import { AxiosInstance, AxiosPromise } from 'axios';
//import { getEnv } from '@/commons/Utils';

import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';

/*
curl --location --request GET 'https://optima.mum1-pp.zeta.in/corporate-core/corporate/v1/esps/98/corporates/17893/companies/18425/employees?groupIDList=80,55&searchTerm=nand' 
--header 'X-Zeta-AuthToken: dWdsTmZldDZpRjR1c2tONTdXb0I4L3p3K3NSME82QkdRRWp4amI2eUhlMG9zMXA0OkFRRzJPYnhkMVduVmNJQVRsMFRwZXFWbTltU01LaUloR1hMMERndVdhWUd6aDJSbTBVNmRkTlFqd2R0Nk9HVHhIOUR0R0h4N0VNcDViWjV6Zk84SFBtQ0hjT0l1QU9TKzJidGx2Mk9hWlU2ZnI4M2d6MkxVY0RTd0dNNjc0SjBkSnlzU0w4VDlyT2xMbUhjdlZpNitCL2RKWDJ1K3ZmOD0='
*/

class CorpCoreService {
    private axios: AxiosInstance;

    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
    }
    private get servicePath() {
        return __APP__.SPOTLIGHT_CORE.BASE_PATH;
    }

    public fetchEmployees(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/employees`,
            { params }
        );
    }
    // public fetchEmployeesByIds(pathParams: any, params: any = {}) {
    //     return this.axios.get(
    //         `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${
    //             pathParams.espId
    //         }/corporates/${pathParams.corpId}/companies/${
    //             pathParams.compId
    //         }/employeeList`,
    //         { params }
    //     );
    // }
    public fetchOrders(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/orders`,
            { params }
        );
    }
    public fetchOrderDetails(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/orders/${pathParams.orderId}`,
            { params }
        );
    }
    public cancelOrder(pathParams: any, params: any = {}) {
        return this.axios.delete(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/orders/${pathParams.orderId}/cancelOrder`
        );
    }
    public fetchEmployee(pathParams: any, params: any = {}) {
        // console.log('###fecthEmployee',pathParams)
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/employees/${pathParams.empId}`,
            { params }
        );
    }
    public fetchEmployeesBulkTempOrder(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/orders/temporary/${pathParams.orderId}/beneficiaries`,
            { params }
        );
    }
    public updateEmployee(pathParams: any, params: any = {}) {
        return this.axios.put(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/employees/${pathParams.empId}`,
            params
        );
    }
    public createEmployee(pathParams: any, params: any = {}) {
        return this.axios.post(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/employeesForOrder`,
            params
        );
    }
    public getGroups(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/groups`,
            { params }
        );
    }

    // to check once more.
    public getGroup(pathParams: any, params: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/groups/${pathParams.id}`,
            { params }
        );
    }
    public getGroupByGroupIdList(pathParams: any, params: any = {}) {
        //used to get nominate award approvers
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/employees`,
            { params }
        );
    }

    public createBulkOrder(pathParams: any, payload: any) {
        return this.axios.post(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/beneficiaries/createEmployeeOrder`,
            payload
        );
    }
    public downloadBulkOrderErrorFile(pathParams: any) {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}/orders/temporary/${pathParams.orderId}/beneficiaries/downloadErrorFile`
        );
    }

    public fetchCompanyAttributes(
        pathParams: any,
        params: any = {}
    ): AxiosPromise<any> {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}`,
            { params }
        );
    }

    public updateCompanyAttributes(
        pathParams: any,
        payload: any = {}
    ): AxiosPromise<any> {
        return this.axios.put(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espId}/corporates/${pathParams.corpId}/companies/${pathParams.compId}?updateSelectedAttributes=true`,
            payload,
            {
                transformResponse: [data => data],
            }
        );
    }

    public fetchTenantInfo(pathParams: any, payload: any): Promise<any> {
        if (pathParams.tenantId) {
            return this.axios.get(
                `${this.servicePath}/tenants/${pathParams.tenantId}/getEmployeeLoginInfo`,
                {
                    params: payload,
                }
            );
        } else {
            return this.axios.get(
                `${__APP__.CORPCORE.BASE_URL}${__APP__.CORPCORE.BASE_PATH}/getEmployeeLoginInfo`,
                {
                    params: payload,
                }
            );
        }
    }
    public fetchUserRoles(pathParams: any, payload: any = {}): Promise<any> {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/getRoles`,
            {
                params: payload,
            }
        );
    }
    public resolveEmployee(payload: any = {}): Promise<any> {
        return this.axios.get(`${this.servicePath}/getEmployeeProfile`, {
            params: payload,
        });
    }
    public getRCtoken(pathParams: any) {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/getConfig`
        );
    }
}

const config = {
    baseURL: __APP__.SPOTLIGHT_CORE.BASE_URL,
    resolveAuthToken: () => {
        return localStorage.getItem('@zeta::authToken') || '';
    },
};

export default new CorpCoreService(config);
